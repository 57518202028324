
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import Chip from '../../atoms/Chip/Chip.vue';
import Tooltip from '@/atomic/atoms/Tooltip/Tooltip.vue';

export default defineComponent({
  components: {
    'arc-chip': Chip,
    'arc-tooltip': Tooltip,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Object,
    },
    status: {
      type: String,
    },
    message: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    auto: {
      type: String,
    },
    inputTitle: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    required: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
  },

  data(): {
    showOptions: boolean;
    selectedValues: IDropdownOption[];
  } {
    return {
      showOptions: false,
      selectedValues: [],
    };
  },

  computed: {
    open(): string {
      return this.showOptions ? 'open' : '';
    },
    filteredOptions(): any[] {
      const titles: string[] = this.selectedValues.map((el: IDropdownOption) => el.description);
      return this.options.filter((el: any) => {
        return titles.includes(el.description) ? null : el;
      });
    },
    selectedValue(): IDropdownOption {
      return (this.modelValue as IDropdownOption) ?? { description: 'Select', value: '' };
    },
    inputStatus(): {
      'input--success': boolean;
      'input--warn': boolean;
      'input--error': boolean;
    } {
      return {
        'input--success': this.status === 'success',
        'input--warn': this.status === 'warn',
        'input--error': this.status === 'error',
      };
    },
  },

  watch: {
    modelValue: {
      deep: true,
      handler() {
        this.selectedValues = this.modelValue as IDropdownOption[];
      },
    },
  },

  methods: {
    toggleDropdownOptionsState(): void {
      if (!this.disabled) {
        this.showOptions = !this.showOptions;
      }
    },
    showDropdownOptions(): void {
      this.showOptions = true;
    },
    hideDropdownOptions(): void {
      this.showOptions = false;
    },
    selectItem(value: IDropdownOption): void {
      this.selectedValues.push(value);
      this.$emit('update:modelValue', this.selectedValues);
    },
    isSelected(option: IDropdownOption): string {
      return option?.value === this.selectedValue?.value ? 'selected' : '';
    },
    removeChip(description: string): void {
      this.selectedValues = this.selectedValues.filter((el: IDropdownOption) => el.description !== description);
      this.$emit('update:modelValue', this.selectedValues);
    },
    selectAllOptions(): void {
      for (const option of this.filteredOptions) {
        this.selectedValues.push(option);
      }
      this.$emit('update:modelValue', this.selectedValues);
    },
  },
});
