
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    status: {
      type: String,
    },
    message: {
      type: String,
    },
    icon: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    auto: {
      type: String,
    },
    inputTitle: {
      type: String,
    },
    showToggle: {
      type: Boolean,
    },
    required: {
      type: Boolean,
      default: false,
    },
    lowercaseOnly: {
      type: Boolean,
      default: false,
    },
  },

  data(): {
    toggleText: string;
  } {
    return {
      toggleText: 'SHOW',
    };
  },

  computed: {
    inputStatus(): {
      'input--success': boolean;
      'input--warn': boolean;
      'input--error': boolean;
    } {
      return {
        'input--success': this.status === 'success',
        'input--warn': this.status === 'warn',
        'input--error': this.status === 'error',
      };
    },
    showIcon(): boolean {
      if (this.icon || ['success', 'error', 'warn'].includes(this.status as string)) {
        return true;
      }

      return false;
    },
    svgIcon(): string {
      if (this.status === 'success') {
        return '#success';
      }

      if (this.status === 'warn') {
        return '#warn';
      }

      if (this.status === 'error') {
        return '#error';
      }

      return this.icon as string;
    },
  },
  methods: {
    toggleShow(): void {
      this.toggleText = this.toggleText === 'HIDE' ? 'SHOW' : 'HIDE';
      this.$emit('toggleShow');
    },
  },
});
