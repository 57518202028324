
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Input from '../../atoms/Input/Input.vue';
import Dropdown from '../../atoms/Dropdown/Dropdown.vue';
import Search from '../../atoms/Search/Search.vue';
import Button from '../../atoms/Button/Button.vue';
import NavTabs from '../../atoms/Nav-Tabs/Nav-Tabs.vue';
import Tooltip from '../../atoms/Tooltip/Tooltip.vue';
import Pagination from '../../../atomic/molecules/Pagination/Pagination.vue';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import Checkbox from '@/atomic/atoms/Checkbox/Checkbox.vue';
import MultiSelectInput from '@/atomic/molecules/Multi-SelectInput/MultiSelectInput.vue';

export default defineComponent({
  props: {
    tabs: {
      type: Array,
      require: true,
    },
    tabsOnly: {
      type: Boolean,
      require: false,
    },
    searchPlaceholder: {
      type: String,
    },
    showValue: {
      type: Object,
    },
    showOptions: {
      type: Array,
    },
    pageValue: {
      type: Object,
    },
    totalPages: {
      type: Number,
    },
    pageOptions: {
      type: Array,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    activeTabFlag: {
      type: String,
    },
    buttonOptions: {
      default: {
        showCreate: true,
        showReports: true,
        showExport: true,
      },
    },
    buttonFeedback: {
      default: {
        exportLoading: false,
        exportSubmitted: false,
      },
    },
    isModal: {
      default: false,
    },
    disableShowValue: {
      default: false,
    },
    showSearch: {
      default: true,
    },
    totalRecords: {
      type: Number,
    },
    showReportCheckboxes: {
      default: false,
    },
    closedStores: {
      default: false,
    },
    closedStoresText: {
      default: 'Show Closed Stores',
    },
    statusFilterValue: {
      type: Object,
    },
    statusFilterOptions: {
      type: Array,
    },
    tagsFilterInputTitle: {
      type: String,
    },
    tagsFilterValues: {
      type: Array,
    },
    tagsFilterOptions: {
      type: Array,
    },
    supportsMarketingFilterValue: {
      type: Object,
    },
    supportsMarketingFilterOptions: {
      type: Array,
    },
  },
  data() {
    return {
      showClosedStores: this.closedStores,
      tagFilters: this.tagsFilterValues,
    };
  },
  components: {
    'arc-nav-tabs': NavTabs,
    'arc-input': Input,
    'arc-dropdown': Dropdown,
    'arc-search': Search,
    'arc-button': Button,
    'arc-pagination': Pagination,
    'arc-tooltip': Tooltip,
    'arc-checkbox': Checkbox,
    'arc-multi-select-input': MultiSelectInput,
  },
  computed: {
    ...mapGetters(['sidebarOpen', 'windowWidth', 'announcements', 'activeAnnouncement', 'tips', 'activeTip', 'activeUpcomingCommunications']),
    exportTooltipText(): string {
      return this.buttonFeedback.exportSubmitted
        ? 'Export is in progress. You will receive an email with the required export file attached once it is ready. You can continue working in the app.'
        : 'Request to export data';
    },
    containerType(): string {
      if (this.tagsFilterOptions && this.tagsFilterOptions.length > 0 && this.statusFilterOptions && this.statusFilterOptions.length > 0) {
        return 'container-with-filters';
      }
      return 'container';
    },
  },
  methods: {
    createClicked(): void {
      this.$emit('createClicked');
    },
    reportsClicked(): void {
      this.$emit('reportsClicked');
    },
    exportClicked(): void {
      this.$emit('exportClicked');
    },
    updateShow(value: IDropdownOption): void {
      this.$emit('updateShow', value);
    },
    updatePage(value: IDropdownOption): void {
      this.$emit('updatePage', value);
    },
    previousPage(): void {
      this.$emit('previousPage');
    },
    nextPage(): void {
      this.$emit('nextPage');
    },
    tabClicked(tab: string): void {
      this.$emit('tabClicked', tab);
    },
    toggleClosedStores(): void {
      this.showClosedStores = !this.showClosedStores;
      this.$emit('toggleClosedStores', this.showClosedStores);
    },
    updateStatusFilter(value: IDropdownOption): void {
      this.$emit('updateStatusFilter', value);
    },
    updateTagsFilter(value: any): void {
      this.tagFilters = value;
      this.$emit('updateTagsFilter', this.tagFilters);
    },
    updateSupportsMarketing(value: IDropdownOption): void {
      this.$emit('updateSupportsMarketing', value);
    },
  },
});
