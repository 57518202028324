/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpService from '@/services/HttpService';
import { EmailCommunicationState, State } from '@vue/runtime-core';
import { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import * as Sentry from '@sentry/vue';
import { Status } from '@/enums/Status';
import captureExceptionInSentry from './errorHandler';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const EmailCommunicationModule = {
  state(): EmailCommunicationState {
    return {
      emailCommunications: [],
      loadingEmailCommunications: false,
    };
  },

  mutations: {
    clearEmailCommunications(state: EmailCommunicationState): void {
      state.emailCommunications = [];
    },
    updateEmailCommunications(state: EmailCommunicationState, payload: any[]): void {
      state.emailCommunications = payload;
    },
    updateLoadingEmailCommunications(state: EmailCommunicationState, payload: boolean): void {
      state.loadingEmailCommunications = payload;
    },
  },

  actions: {
    clearEmailCommunications(context: ActionContext<EmailCommunicationState, State>): void {
      context.commit('clearEmailCommunications');
    },

    async fetchEmailCommunications(
      context: ActionContext<EmailCommunicationState, State>,
      payload: { page?: number; limit?: number; search?: string; sortKey?: string; sortDirection?: string },
    ): Promise<any> {
      context.commit('updateLoadingEmailCommunications', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getEmailCommunications(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
        );
        context.commit('updateEmailCommunications', records.data.data);
        context.commit('updateLoadingEmailCommunications', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingEmailCommunications', false);
      }
    },

    async fetchEmailCommunication(context: ActionContext<EmailCommunicationState, State>, payload: { emailCommunicationId: string }): Promise<any> {
      try {
        const records: AxiosResponse<Response> = await new HttpService().getEmailCommunication(payload.emailCommunicationId);
        return records?.data?.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    saveEmailCommunication(context: ActionContext<EmailCommunicationState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveEmailCommunication(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchEmailCommunications');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },
  },

  getters: {
    emailCommunications(state: EmailCommunicationState): any[] {
      return state.emailCommunications;
    },
    loadingEmails(state: EmailCommunicationState): boolean {
      return state.loadingEmailCommunications;
    },
  },
};
