import { createStore } from 'vuex';
import { UserModule } from './user';
import { UtilityModule } from './utility';
import { AuthenticationModule } from './authentication';
import { ModalModule } from './modal';
import { NotificationModule } from './notification';
import { SidebarModule } from './sidebar';
import { CalendarModule } from './calendar';
import { CommunicationModule } from './communication';
import { ReportModule } from './report';
import { ReviewModule } from './review';
import { MallModule } from './mall';
import { StoreModule } from './store';
import { CompanyModule } from './company';
import { BrandModule } from './brand';
import { UsersModule } from './users';
import { MetadataModule } from './metadata';
import { LoadingModule } from './loading';
import { EmailConnectionModule } from './emailConnection';
import { OpportunityModule } from '@/store/opportunity';
import { ContactsModule } from '@/store/contacts';
import { PaginationModule } from '@/store/pagination';
import { TagModule } from '@/store/tags';
import { EmailCommunicationModule } from '@/store/emailCommunication';

export default createStore({
  modules: {
    UtilityModule,
    AuthenticationModule,
    ModalModule,
    NotificationModule,
    SidebarModule,
    CalendarModule,
    CommunicationModule,
    ReportModule,
    ReviewModule,
    UserModule,
    MallModule,
    StoreModule,
    CompanyModule,
    BrandModule,
    UsersModule,
    EmailConnectionModule,
    OpportunityModule,
    MetadataModule,
    LoadingModule,
    ContactsModule,
    PaginationModule,
    TagModule,
    EmailCommunicationModule,
  },
});
