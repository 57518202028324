/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpService from '@/services/HttpService';
import { ReviewState, State } from '@vue/runtime-core';
import { isArray } from '@vue/shared';
import { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import * as Sentry from '@sentry/vue';
import captureExceptionInSentry from './errorHandler';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const ReviewModule = {
  state(): ReviewState {
    return {
      marketingCampaignReview: {},
      loadingMarketingCampaignReview: false,
      recruitmentCampaignReview: {},
      loadingRecruitmentCampaignReview: false,
      mallMemoReview: {},
      loadingMallMemoReview: false,
      storeMemoReview: {},
      loadingStoreMemoReview: false,
      reviewType: '',
      loadingStoreStatus: false,
      declineReasons: [],
      channels: [],
      contactChannels: [],
      mallsChannels: [],
      systemEmails: [],
      marketingOpportunityReview: {},
      loadingMarketingOpportunityReview: false,
      loadingMarketingOpportunityMallId: false,
    };
  },

  mutations: {
    updateMarketingCampaignReview(state: ReviewState, payload: any): void {
      state.marketingCampaignReview = payload;
    },
    updateLoadingMarketingCampaignReview(state: ReviewState, payload: boolean): void {
      state.loadingMarketingCampaignReview = payload;
    },
    updateRecruitmentCampaignReview(state: ReviewState, payload: any): void {
      state.recruitmentCampaignReview = payload;
    },
    updateLoadingRecruitmentCampaignReview(state: ReviewState, payload: boolean): void {
      state.loadingRecruitmentCampaignReview = payload;
    },
    updateMallMemoReview(state: ReviewState, payload: any): void {
      state.mallMemoReview = payload;
    },
    updateLoadingMallMemoReview(state: ReviewState, payload: boolean): void {
      state.loadingMallMemoReview = payload;
    },
    updateStoreMemoReview(state: ReviewState, payload: any): void {
      state.storeMemoReview = payload;
    },
    updateLoadingStoreMemoReview(state: ReviewState, payload: boolean): void {
      state.loadingStoreMemoReview = payload;
    },
    updateLoadingStoreStatus(state: ReviewState, payload: boolean): void {
      state.loadingStoreStatus = payload;
    },
    updateReviewType(state: ReviewState, payload: string): void {
      state.reviewType = payload;
    },
    updateDeclineReasons(state: ReviewState, payload: string[]): void {
      state.declineReasons = payload;
    },
    updateChannels(state: ReviewState, payload: string[]): void {
      state.channels = payload;
    },
    updateContactChannels(state: ReviewState, payload: string[]): void {
      state.contactChannels = payload;
    },
    updateMallsChannels(state: ReviewState, payload: string[]): void {
      state.mallsChannels = payload;
    },
    updateSystemEmails(state: ReviewState, payload: any[]): void {
      state.systemEmails = payload;
    },
    updateMarketingOpportunityReview(state: ReviewState, payload: any): void {
      state.marketingOpportunityReview = payload;
    },
    updateLoadingMarketingOpportunityReview(state: ReviewState, payload: boolean): void {
      state.loadingMarketingOpportunityReview = payload;
    },
    updateLoadingMarketingOpportunityMallId(state: ReviewState, payload: boolean): void {
      state.loadingMarketingOpportunityMallId = payload;
    },
  },

  actions: {
    async fetchReviewPageInformationViaPublicId(context: ActionContext<ReviewState, State>, payload: string): Promise<any> {
      context.commit('updateLoadingMarketingCampaignReview', true);
      context.commit('updateLoadingRecruitmentCampaignReview', true);
      context.commit('updateLoadingMallMemoReview', true);
      context.commit('updateLoadingStoreMemoReview', true);

      try {
        const records: AxiosResponse<Response> = await new HttpService().getReviewPageInformationViaPublicId(payload);

        context.commit('updateLoadingMarketingCampaignReview', false);
        context.commit('updateLoadingRecruitmentCampaignReview', false);
        context.commit('updateLoadingMallMemoReview', false);
        context.commit('updateLoadingStoreMemoReview', false);
        return {
          valid: records?.data?.success,
          data: records?.data?.data,
        };
      } catch (err) {
        context.commit('updateLoadingMarketingCampaignReview', false);
        context.commit('updateLoadingRecruitmentCampaignReview', false);
        context.commit('updateLoadingMallMemoReview', false);
        context.commit('updateLoadingStoreMemoReview', false);

        const errorAsAny = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    async fetchMarketingCampaignReview(
      context: ActionContext<ReviewState, State>,
      payload: { type: string; campaignId?: string; id?: string; userId?: string; mallId?: string; },
    ): Promise<any> {
      context.commit('updateLoadingMarketingCampaignReview', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMarketingCampaignReview(
          payload.type,
          payload.campaignId,
          payload.id,
          payload.userId,
          payload.mallId,
        );

        if (isArray(records?.data?.data)) {
          for (const record of records?.data?.data) {
            record.uniqueId = record.campaignNumber;
          }
        }

        context.commit('updateMarketingCampaignReview', records?.data?.data);
        context.commit('updateLoadingMarketingCampaignReview', false);
        return records?.data?.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingMarketingCampaignReview', false);
      }
    },
    async fetchRecruitmentCampaignReview(
      context: ActionContext<ReviewState, State>,
      payload: { type: string; campaignId?: string; id?: string; userId?: string; mallId?: string; },
    ): Promise<any> {
      context.commit('updateLoadingRecruitmentCampaignReview', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getRecruitmentCampaignReview(
          payload.type,
          payload.campaignId,
          payload.id,
          payload.userId,
          payload.mallId,
        );

        if (isArray(records?.data.data)) {
          for (const record of records?.data.data) {
            record.uniqueId = record.campaignNumber;
          }
        }

        context.commit('updateRecruitmentCampaignReview', records?.data?.data);
        context.commit('updateLoadingRecruitmentCampaignReview', false);
        return records?.data?.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingRecruitmentCampaignReview', false);
      }
    },
    async fetchMallMemoReview(
      context: ActionContext<ReviewState, State>,
      payload: { type: string; memoId?: string; id?: string; userId?: string; mallId?: string },
    ): Promise<any> {
      context.commit('updateLoadingMallMemoReview', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMallMemoReview(
          payload.type,
          payload.memoId,
          payload.id,
          payload.userId,
          payload.mallId,
        );

        if (isArray(records?.data.data)) {
          for (const record of records?.data.data) {
            record.uniqueId = record.memoNumber;
          }
        }

        context.commit('updateMallMemoReview', records?.data);
        context.commit('updateLoadingMallMemoReview', false);
        return records?.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingMallMemoReview', false);
      }
    },
    async fetchStoreMemoReview(
      context: ActionContext<ReviewState, State>,
      payload: { type: string; memoId?: string; id?: string; userId?: string },
    ): Promise<any> {
      context.commit('updateLoadingStoreMemoReview', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getStoreMemoReview(payload.type, payload.memoId, payload.id, payload.userId);

        if (isArray(records?.data.data)) {
          for (const record of records?.data.data) {
            record.commSubTypeText = record?.type?.description;
            record.commMemoSubTypeText = record?.subtype?.description;
            record.uniqueId = record.memoNumber;
          }
        }

        context.commit('updateStoreMemoReview', records?.data);
        context.commit('updateLoadingStoreMemoReview', false);
        return records?.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingStoreMemoReview', false);
      }
    },
    async fetchDeclineReasons(context: ActionContext<ReviewState, State>): Promise<void> {
      const records: AxiosResponse<Response> = await new HttpService().getMetaDeclineReasons();
      context.commit('updateDeclineReasons', records.data);
    },
    async fetchChannels(context: ActionContext<ReviewState, State>): Promise<void> {
      const records: AxiosResponse<Response> = await new HttpService().getMetaChannels();
      context.commit('updateChannels', records.data);
    },
    async fetchContactChannels(context: ActionContext<ReviewState, State>, payload: { contactId: string }): Promise<void> {
      const records: AxiosResponse<Response> = await new HttpService().getContactChannels(payload.contactId);
      context.commit('updateContactChannels', records.data);
    },
    async fetchMallsChannels(context: ActionContext<ReviewState, State>, payload: { storeIds: string[], marketingCampaignId: string, recruitmentCampaignId: string }): Promise<void> {
      const records: AxiosResponse<Response> = await new HttpService().getMallsChannels(payload.storeIds, payload.marketingCampaignId, payload.recruitmentCampaignId);
      context.commit('updateMallsChannels', records.data);
    },
    async fetchSystemEmails(context: ActionContext<ReviewState, State>, payload: { communicationId: string, mallId?: string }): Promise<void> {
      const records: AxiosResponse<Response> = await new HttpService().getSystemEmails(payload.communicationId, payload.mallId);

      if (isArray(records.data?.data)) {
        for (const record of records.data?.data) {
          record.emailSubjectWithUrl = { text: record.emailSubject, url: record.url };
        }
      }
      context.commit('updateSystemEmails', records.data?.data);
    },
    async setMarketingCampaignReviewStoreStatus(
      context: ActionContext<ReviewState, State>,
      payload: {
        campaignId: string;
        storeIds: number[];
        statusId: number;
        reasonForDeclineId: number;
        reasonForDeclineDescription: string;
        route?: string;
        channels?: number[];
        currentStatusId?: number;
        adminApproval?: boolean;
      },
    ): Promise<any> {
      context.commit('updateLoadingStoreStatus', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().setMarketingCampaignReviewStoreStatus(
          payload.campaignId,
          payload.storeIds,
          payload.statusId,
          payload.reasonForDeclineId,
          payload.reasonForDeclineDescription,
          payload.route,
          payload.channels,
          payload.currentStatusId,
          payload.adminApproval,
        );
        context.commit('updateLoadingStoreStatus', false);
        return records;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingStoreStatus', false);
      }
    },
    async setRecruitmentCampaignReviewStoreStatus(
      context: ActionContext<ReviewState, State>,
      payload: {
        campaignId: string;
        storeIds: number[];
        statusId: number;
        socialStatusId: number;
        reasonForDeclineId: number;
        reasonForDeclineDescription: string;
        type?: string | undefined;
        channels?: number[];
        currentStatusId?: number;
        adminApproval?: boolean;
        route?: string;
      },
    ): Promise<any> {
      context.commit('updateLoadingStoreStatus', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().setRecruitmentCampaignReviewStoreStatus(
          payload.campaignId,
          payload.storeIds,
          payload.statusId,
          payload.socialStatusId,
          payload.reasonForDeclineId,
          payload.reasonForDeclineDescription,
          payload.type,
          payload.channels,
          payload.currentStatusId,
          payload.adminApproval,
          payload.route,
        );
        context.commit('updateLoadingStoreStatus', false);
        return records;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingStoreStatus', false);
      }
    },
    async setMallMemoReviewStoreStatus(
      context: ActionContext<ReviewState, State>,
      payload: {
        memoId: string;
        storeIds: number[];
        statusId: number;
        reasonForDeclineId: number;
        reasonForDeclineDescription: string;
        answers?: any;
        type?: string | undefined;
        currentStatusId?: number;
        route?: string | undefined;
      },
    ): Promise<any> {
      context.commit('updateLoadingStoreStatus', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().setMallMemoReviewStoreStatus(
          payload.memoId,
          payload.storeIds,
          payload.statusId,
          payload.reasonForDeclineId,
          payload.reasonForDeclineDescription,
          payload.answers,
          payload.type,
          payload.currentStatusId,
          payload.route,
        );
        context.commit('updateLoadingStoreStatus', false);
        return records;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingStoreStatus', false);
      }
    },
    async setStoreMemoReviewStoreStatus(
      context: ActionContext<ReviewState, State>,
      payload: {
        memoId: string;
        storeIds: number[];
        statusId: number;
        reasonForDeclineId: number;
        reasonForDeclineDescription: string;
        answers?: any;
        type?: string | undefined;
        currentStatusId?: number;
        route?: string | undefined;
      },
    ): Promise<any> {
      context.commit('updateLoadingStoreStatus', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().setStoreMemoReviewStoreStatus(
          payload.memoId,
          payload.storeIds,
          payload.statusId,
          payload.reasonForDeclineId,
          payload.reasonForDeclineDescription,
          payload.answers,
          payload.type,
          payload.currentStatusId,
          payload.route,
        );
        context.commit('updateLoadingStoreStatus', false);
        return records;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingStoreStatus', false);
      }
    },
    updateReviewType(context: ActionContext<ReviewState, State>, payload: string): void {
      context.commit('updateReviewType', payload);
    },
    async fetchMarketingOpportunityReview(
      context: ActionContext<ReviewState, State>,
      payload: { emailId?: string; opportunityId?: string },
    ): Promise<any> {
      context.commit('updateLoadingMarketingOpportunityReview', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMarketingOpportunityReview(payload.emailId, payload.opportunityId);

        if (isArray(records?.data?.data)) {
          for (const record of records?.data?.data) {
            record.uniqueId = record.campaignNumber;
          }
        }

        context.commit('updateMarketingOpportunityReview', records?.data?.data);
        context.commit('updateLoadingMarketingOpportunityReview', false);
        return records?.data?.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingMarketingOpportunityReview', false);
      }
    },
    async setMarketingOpportunityMallId(
      context: ActionContext<ReviewState, State>,
      payload: { emailId: string; opportunityId: string; mallId: string },
    ): Promise<any> {
      context.commit('updateLoadingMarketingOpportunityMallId', true);
      return new Promise((resolve, reject) => {
        new HttpService()
          .setMarketingOpportunityMallId(payload.emailId, payload.opportunityId, payload.mallId)
          .then((records: AxiosResponse<Response>) => {
            context.commit('updateLoadingMarketingOpportunityMallId', false);
            resolve(records);
          })
          .catch(({ request }) => {
            context.commit('updateLoadingMarketingOpportunityMallId', false);
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },
  },

  getters: {
    marketingCampaignReview(state: ReviewState): any | null {
      return state?.marketingCampaignReview || null;
    },
    loadingMarketingCampaignReview(state: ReviewState): boolean {
      return state?.loadingMarketingCampaignReview;
    },
    recruitmentCampaignReview(state: ReviewState): any | null {
      return state?.recruitmentCampaignReview || null;
    },
    loadingRecruitmentCampaignReview(state: ReviewState): boolean {
      return state?.loadingRecruitmentCampaignReview;
    },
    mallMemoReview(state: ReviewState): any | null {
      return state?.mallMemoReview || null;
    },
    loadingMallMemoReview(state: ReviewState): boolean {
      return state?.loadingMallMemoReview;
    },
    storeMemoReview(state: ReviewState): any | null {
      return state?.storeMemoReview || null;
    },
    loadingStoreMemoReview(state: ReviewState): boolean {
      return state?.loadingStoreMemoReview;
    },
    loadingStoreStatus(state: ReviewState): boolean {
      return state?.loadingStoreStatus;
    },
    reviewType(state: ReviewState): string {
      return state?.reviewType;
    },
    declineReasons(state: ReviewState): string[] {
      return state?.declineReasons;
    },
    metaChannels(state: ReviewState): string[] {
      return state?.channels;
    },
    contactChannels(state: ReviewState): string[] {
      return state?.contactChannels;
    },
    mallsChannels(state: ReviewState): string[] {
      return state?.mallsChannels;
    },
    systemEmails(state: ReviewState): any[] {
      return state?.systemEmails;
    },
    marketingOpportunityReview(state: ReviewState): any | null {
      return state?.marketingOpportunityReview;
    },
    loadingMarketingOpportunityReview(state: ReviewState): boolean {
      return state?.loadingMarketingOpportunityReview;
    },
    loadingMarketingOpportunityMallId(state: ReviewState): boolean {
      return state?.loadingMarketingOpportunityMallId;
    },
  },
};
