
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../atoms/Input/Input.vue';
import Dropdown from '../../../../atoms/Dropdown/Dropdown.vue';
import MultiSelectDropdown from '../../../../molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import Button from '../../../../atoms/Button/Button.vue';
import Tooltip from '../../../../atoms/Tooltip/Tooltip.vue';
import Modal from '../../../../atoms/Modal/Modal.vue';
import DatePicker from '../../../../molecules/DatePicker/DatePicker.vue';
import { IContact, ITag } from '../../../../../../../../interfaces/src/v2/index';
import { FormBuilder, FormControl } from '../../../../../utilities/Forms';
import { Validators } from '../../../../../utilities/Validators/Validators';
import { IDropdownOption } from '../../../../../utilities/Interfaces/form.interfaces';
import SingleSelectInputSuggestion from '../../../../molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import MultiSelectInput from '../../../../molecules/Multi-SelectInput/MultiSelectInput.vue';
import { dateFormatter } from '../../../../../utilities/Functions/formatting';
import Loader from '../../../../atoms/Loader/Loader.vue';
import Checkbox from '@/atomic/atoms/Checkbox/Checkbox.vue';

export default defineComponent({
  emits: {
    hasChangesMade: Boolean,
  },
  components: {
    'arc-input': Input,
    'arc-dropdown': Dropdown,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-multi-select-input': MultiSelectInput,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
    'arc-date-picker': DatePicker,
    'arc-loader': Loader,
    'arc-checkbox': Checkbox,
  },

  computed: {
    ...mapGetters([
      'modalData',
      'user',
      'access',
      'permissions',
      'storeStatuses',
      'companyOptions',
      'brandOptions',
      'mallOptions',
      'countries',
      'provinces',
      'brandTagOptions',
      'communicationTypes',
      'languageOptions',
      'contactTypes',
      'appleCategoriesOptions',
    ]),
    updateDisabled(): boolean {
      return (
        this.permissions &&
        this.modalData?.row &&
        !this.permissions?.canUpdateAllStores &&
        (!this.permissions?.canUpdateOwnedStores ||
          (this.permissions?.canUpdateOwnedStores && this.user?.userId !== this.modalData?.row?.createdByUserId))
      );
    },
    deleteDisabled(): boolean {
      return (
        this.permissions &&
        this.modalData?.row &&
        !this.permissions?.canDeleteAllStores &&
        (!this.permissions?.canDeleteOwnedStores ||
          (this.permissions?.canDeleteOwnedStores && this.user?.userId !== this.modalData?.row?.createdByUserId))
      );
    },
    selectBrandPlaceholder(): string {
      return this.brandOptions && this.brandOptions.length > 0 ? 'Select' : 'Select Company First';
    },
    countryFieldWidth(): string {
      let style = 'grid-column-end: span 10;';
      if (this.countryValue?.value && this.provinces.length > 0) {
        style = 'grid-column-end: span 5;';
      }
      return style;
    },
    firstRowField(): string {
      return this.permissions?.superAdminAccess ? 'grid-column-end: span 5;' : 'grid-column-end: span 6;';
    },
    firstRowFieldStoreNumber(): string {
      return this.permissions?.superAdminAccess ? 'grid-column-end: span 2;' : 'grid-column-end: span 3;';
    },
    firstRowFieldStoreIdentifier(): string {
      return this.permissions?.superAdminAccess ? 'grid-column-end: span 3;' : 'grid-column-end: span 3;';
    },
    provinceTitle(): string {
      return this.countryValue?.value === 2 ? 'Province' : 'State';
    },
    commTypeFieldWidth(): string {
      return this.commTypeValues?.length > 0 ? 'width: calc(75% - 24rem); margin-bottom: 1rem;' : 'width: 70%; margin-bottom: 1rem;';
    },
    containerType(): string {
      return this.permissions?.superAdminAccess ? 'admin-container' : 'user-container';
    },
    filteredCommunicationTypes(): any[] {
      return this.communicationTypes.filter((type: IDropdownOption) => type.value !== 4);
    },
  },

  watch: {
    email: {
      deep: true,
      handler() {
        this.formData.controls.e.value = this.email?.trim();
        this.validateControl('e');
      },
    },
    contactTypes: {
      deep: true,
      handler() {
        this.contactTypes.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.contactTypeId === item?.value) {
            this.contactTypeValue = item;
          }
        });
      },
    },
    actionRequired: {
      deep: true,
      handler() {
        this.notificationOnly = !this.actionRequired;
      },
    },
    'formData.controls.openDate.value': {
      deep: true,
      handler() {
        if (this.formData.controls.openDate.value && this.formData.controls.openDate.value.includes('/')) {
          let open: Date;
          open = new Date(`${this.formData?.controls?.openDate?.value}`);
          this.formData.controls.openDate.value = open.toISOString().slice(0, 10);
        }
      },
    },
    'formData.controls.closeDate.value': {
      deep: true,
      handler() {
        if (this.formData.controls.closeDate.value && this.formData.controls.closeDate.value.includes('/')) {
          let close: Date;
          close = new Date(`${this.formData?.controls?.closeDate?.value}`);
          this.formData.controls.closeDate.value = close.toISOString().slice(0, 10);
        }
      },
    },
    mallOptions: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.allowShowOptions = true;
        }, 250);
        this.mallOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.mallId === item?.value) {
            this.mallValue = item;
          }
        });
      },
    },
    companyOptions: {
      deep: true,
      handler() {
        if (this.loaded) {
          setTimeout(() => {
            this.allowCompanyShowOptions = true;
          }, 250);
          this.companyOptions.forEach((item: IDropdownOption) => {
            if (this.permissions && !this.permissions?.superAdminAccess && this.user?.companyId === item?.value) {
              this.updateCompany(item);
            } else if (this.modalData?.row?.companyId === item?.value) {
              this.updateCompany(item);
            }
          });
        } else {
          setTimeout(() => {
            const co = this.companyOptions;
            this.companyOptions = co;
          }, 1000);
        }
      },
    },
    brandOptions: {
      deep: true,
      handler() {
        this.brandOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.brandId === item?.value) {
            this.brandValue = item;
            if (this.brandValue?.value) {
              this.loadBrandTags({ brandId: this.brandValue.value });
            }
          }
        });
      },
    },
    storeStatuses: {
      deep: true,
      handler() {
        this.storeStatuses.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.statusId === item?.value) {
            this.statusValue = item;
          }
        });
      },
    },
    languageOptions: {
      deep: true,
      handler() {
        this.languageOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.languageId === item?.value) {
            this.languageValue = item;
          }
        });
      },
    },
    countries: {
      deep: true,
      handler() {
        this.countries.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.country === item?.description) {
            this.countryValue = item;
            this.loadProvinceOptions(this.countryValue.value);
          }
        });
      },
    },
    provinces: {
      deep: true,
      handler() {
        this.provinces.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.province === item?.description) {
            this.provinceValue = item;
          }
        });
      },
    },
    brandTagOptions: {
      deep: true,
      handler() {
        this.tagValues = [];
        this.brandTagOptions.forEach((item: IDropdownOption) => {
          if (this.formData?.controls?.tags.value) {
            this.formData.controls.tags.value.forEach((tag: number) => {
              if (tag === item.value) {
                this.tagValues.push(item);
              }
            });
          }
        });
      },
    },
    appleCategoriesOptions: {
      deep: true,
      handler() {
        this.appleCategoriesValues = [];
        this.appleCategoriesOptions.forEach((item: IDropdownOption) => {
          if (this.formData?.controls?.appleCategories.value) {
            this.formData.controls.appleCategories.value.forEach((appleCategory: string) => {
              if (appleCategory === item.value) {
                this.appleCategoriesValues.push(item);
              }
            });
          }
        });
      },
    },
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
  },

  async mounted(): Promise<void> {
    if (this.modalData?.storeId) {
      this.modalData.row = await this.fetchStore({ storeId: this.modalData?.storeId });
    }

    this.loadCompanies();
    this.loadStoreStatuses();
    this.loadMallOptions();
    this.loadCountryOptions();
    this.loadContactTypes('store');
    this.loadStoreCommunicationTypes();
    this.loadLanguageOptions();
    this.loadAppleCategories();
    this.setForm();
    this.loaded = true;
  },
  data(): {
    mallValue: IDropdownOption | null;
    companyValue: IDropdownOption | null;
    brandValue: IDropdownOption | null;
    statusValue: IDropdownOption | null;
    countryValue: IDropdownOption | null;
    provinceValue: IDropdownOption | null;
    languageValue: IDropdownOption | null;
    allowShowOptions: boolean;
    allowCompanyShowOptions: boolean;
    editingContact: boolean;
    fName: string;
    lName: string;
    title: string;
    email: string;
    phone: string;
    commTypeValues: IDropdownOption[] | [];
    tagValues: IDropdownOption[];
    contacts: any[];
    contactBeingEdited: any;
    contactTypeValue: IDropdownOption | null;
    emailTypeValue: IDropdownOption | null;
    emailTypes: IDropdownOption[];
    appleCategoriesValues: IDropdownOption[];
    status: string;
    changesMade: boolean;
    formValid: boolean;
    promptOpen: boolean;
    promptType: string;
    submitting: boolean;
    updating: boolean;
    saving: boolean;
    expiring: boolean;
    deleting: boolean;
    formData: any;
    loaded: boolean;
    actionRequired: boolean;
    notificationOnly: boolean;
    appleBusiness: boolean;
  } {
    return {
      mallValue: null,
      companyValue: null,
      brandValue: null,
      statusValue: null,
      countryValue: null,
      provinceValue: null,
      languageValue: null,
      allowShowOptions: false,
      allowCompanyShowOptions: false,
      editingContact: false,
      fName: '',
      lName: '',
      title: '',
      email: '',
      phone: '',
      commTypeValues: [],
      contacts: [],
      contactBeingEdited: null,
      contactTypeValue: null,
      emailTypeValue: null,
      emailTypes: [
        { value: 'to', description: 'To' },
        { value: 'cc', description: 'CC' },
      ],
      status: 'Draft',
      changesMade: false,
      formValid: true,
      promptOpen: false,
      promptType: 'save',
      submitting: false,
      updating: false,
      saving: false,
      expiring: false,
      deleting: false,
      formData: null,
      loaded: false,
      tagValues: [],
      appleCategoriesValues: [],
      actionRequired: true,
      notificationOnly: false,
      appleBusiness: false,
    };
  },

  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'loadCompanies',
      'loadBrands',
      'loadStoreStatuses',
      'loadMallOptions',
      'loadCountryOptions',
      'loadProvinceOptions',
      'loadBrandTags',
      'clearBrandTags',
      'loadLanguageOptions',
      'loadStoreCommunicationTypes',
      'saveStore',
      'updateStore',
      'deleteStore',
      'loadContactTypes',
      'fetchStore',
      'loadAppleCategories',
      'undeleteStore',
    ]),
    setForm(): void {
      // initializers
      let languageId = '';
      let mall = '';
      let company = '';
      let brand = '';
      let storeNumber = '';
      let status = '';
      let openDate: any = null;
      let closeDate: any = null;
      let phoneNumber = '';
      let streetAddress = '';
      let city = '';
      let country = '';
      let province = '';
      let postalCode = '';
      let facebookUrl = '';
      let instagramUrl = '';
      let twitterUrl = '';
      let tags: number[] = [];
      let storeIdentifier = '';
      let googleBusinessLocationId = '';
      let appleBusiness = false;
      let appleBusinessLocationId = '';
      let appleCategories: string[] = [];

      if (this.modalData?.row) {
        languageId = this.modalData?.row?.languageId;
        mall = this.modalData?.row?.mallId;
        company = this.modalData?.row?.companyId;
        brand = this.modalData?.row?.brandId;
        storeNumber = this.modalData?.row?.storeNumber.toString();
        status = this.modalData?.row?.statusId;
        phoneNumber = this.modalData?.row?.phoneNumber;
        streetAddress = this.modalData?.row?.streetAddress;
        city = this.modalData?.row?.city;
        country = this.modalData?.row?.country;
        province = this.modalData?.row?.province;
        postalCode = this.modalData?.row?.postalCode;
        this.contacts = this.modalData?.row?.contacts;
        facebookUrl = this.modalData?.row?.facebookUrl;
        twitterUrl = this.modalData?.row?.twitterUrl;
        instagramUrl = this.modalData?.row?.instagramUrl;
        storeIdentifier = this.modalData?.row?.storeIdentifier;
        googleBusinessLocationId = this.modalData?.row?.googleBusinessLocationId ?? '';
        appleBusiness = this.modalData?.row?.appleBusiness ?? false;
        this.appleBusiness = appleBusiness;
        appleBusinessLocationId = this.modalData?.row?.appleBusinessLocationId ?? '';
        appleCategories = this.modalData?.row?.appleCategories;

        // set tagIds
        this.modalData?.row?.tags?.forEach((tag: ITag) => {
          tags.push(tag.tagId);
        });

        if (this.modalData?.row?.openDate) {
          openDate = this.modalData?.row?.openDate;
        }
        if (this.modalData?.row?.closeDate) {
          closeDate = this.modalData?.row?.closeDate;
        }
      }

      // set form based on user type
      this.formData = new FormBuilder({
        e: new FormControl('', [Validators.email]),
        languageId: new FormControl(languageId, [Validators.required]),
        mall: new FormControl(mall, [Validators.required]),
        company: new FormControl(company, [Validators.required]),
        brand: new FormControl(brand, [Validators.required]),
        storeNumber: new FormControl(storeNumber, [Validators.required]),
        status: new FormControl(status, [Validators.required]),
        openDate: new FormControl(openDate),
        closeDate: new FormControl(closeDate),
        phoneNumber: new FormControl(phoneNumber),
        streetAddress: new FormControl(streetAddress),
        city: new FormControl(city),
        country: new FormControl(country),
        province: new FormControl(province),
        postalCode: new FormControl(postalCode),
        facebookUrl: new FormControl(facebookUrl),
        twitterUrl: new FormControl(twitterUrl),
        instagramUrl: new FormControl(instagramUrl),
        tags: new FormControl(tags),
        storeIdentifier: new FormControl(storeIdentifier),
        googleBusinessLocationId: new FormControl(googleBusinessLocationId),
        appleBusiness: new FormControl(appleBusiness),
        appleBusinessLocationId: new FormControl(appleBusinessLocationId),
        appleCategories: new FormControl(appleCategories),
      });
      setTimeout(() => {
        this.formData.controls.openDate.value = openDate;
        this.formData.controls.closeDate.value = closeDate;
      }, 0);
    },
    updateCompany(value: IDropdownOption, fromForm = false) {
      this.brandValue = null;
      this.companyValue = value;
      this.formData.controls.company.value = this.companyValue.value;
      this.loadBrands({ companyId: value.value });
      if (fromForm) {
        this.validateControl('company');
        this.setChangesMade('company');
        this.tagValues = [];
        this.clearBrandTags();
      }
    },
    clearCompany(value: any) {
      if (value === '') {
        this.companyValue = null;
        this.formData.controls.company.value = null;
        this.setChangesMade('clear company');
        this.validateControl('company');
      }
    },
    updateCountry(value: IDropdownOption) {
      this.countryValue = value;
      this.formData.controls.country.value = this.countryValue.description;
      this.provinceValue = null;
      this.loadProvinceOptions(this.countryValue.value);
      if (this.appleBusiness && (this.countryValue.value === 2 || this.countryValue.value === 5)) {
        this.formData.controls.province.validators = [Validators.required];
      } else {
        this.formData.controls.province.validators = [];
      }
      this.validateControl('country');
      this.setChangesMade('country');
    },
    updateLanguage(value: IDropdownOption) {
      this.languageValue = value;
      this.formData.controls.languageId.value = this.languageValue.value;
      this.validateControl('languageId');
    },
    updateProvince(value: IDropdownOption) {
      this.provinceValue = value;
      this.formData.controls.province.value = this.provinceValue.description;
      this.setChangesMade('province');
    },
    updateContactType(value: IDropdownOption) {
      this.contactTypeValue = value;
      this.setChangesMade('contact type');
    },
    updateEmailType(value: IDropdownOption) {
      this.emailTypeValue = value;
      this.setChangesMade('email type');
    },
    getContactType(id: number) {
      let item = '';
      this.contactTypes.forEach((type: IDropdownOption) => {
        if (type.value === id) {
          item = type.description;
        }
      });
      return item;
    },
    updateStatus(value: IDropdownOption) {
      this.statusValue = value;
      this.formData.controls.status.value = this.statusValue.value;
      this.validateControl('status');
      this.formData.controls.openDate.value = null;
      this.formData.controls.closeDate.value = null;
    },
    updateBrand(value: IDropdownOption, fromForm = false) {
      this.brandValue = value;
      this.formData.controls.brand.value = this.brandValue?.value;
      if (fromForm) {
        this.setChangesMade('brand');
        if (this.brandValue) {
          this.tagValues = [];
          this.loadBrandTags({ brandId: this.brandValue.value });
        }
      }
      this.validateControl('brand');
    },
    updateMall(value: IDropdownOption) {
      this.mallValue = value;
      this.formData.controls.mall.value = value.value;
      this.setChangesMade('mall');
      this.validateControl('mall');
    },
    clearMall(value: any) {
      if (value === '') {
        this.mallValue = null;
        this.formData.controls.mall.value = null;
        this.setChangesMade('clear mall');
        this.validateControl('mall');
      }
    },
    tagsUpdated(value: any): void {
      this.tagValues = value;
      this.setChangesMade('tags');
    },
    appleCategoriesUpdated(value: any): void {
      this.appleCategoriesValues = value;
      this.formData.controls.appleCategories.value = value;
      this.setChangesMade('apple categories');
    },
    commTypesUpdated(value: any): void {
      this.commTypeValues = value;
      this.setChangesMade('comm types');
    },
    setChangesMade(type?: string) {
      this.changesMade = true;
    },
    validateControl(controlName: string): void {
      setTimeout(() => {
        this.formData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setChangesMade('validate control');
        }
      }, 0);
    },
    formatDate(date: string): string {
      return dateFormatter(date);
    },
    getCommunicationTypes(ids: any[]): string {
      let types = '';
      if (ids) {
        ids.forEach((id) => {
          this.communicationTypes.forEach((type: any) => {
            if (typeof id === 'object') {
              if (type.value === id.communicationTypeId) {
                types = types + type.description + ', ';
              }
            }
            if (typeof id === 'number') {
              if (type.value === id) {
                types = types + type.description + ', ';
              }
            }
          });
        });
        types = types.slice(0, types.length - 2);
      }
      return types;
    },
    addContact() {
      if (this.email && this.contactTypeValue && this.emailTypeValue && this.formData?.controls?.e?.errors?.status === 'success') {
        let emails = this.contacts.map((contact) => contact.email?.trim());
        if (emails.includes(this.email?.trim())) {
          this.$notify({
            title: 'ERROR',
            text: 'A contact with this email already exists!',
            type: 'error',
            duration: 5000,
          });
        } else {
          this.contacts.push({
            firstName: this.fName,
            lastName: this.lName,
            email: this.email?.trim(),
            title: this.title,
            phoneNumber: this.phone,
            emailType: this.emailTypeValue.value,
            actionRequired: this.actionRequired,
            notificationOnly: this.notificationOnly,
            contactTypeId: this.contactTypeValue?.value as number,
            communicationTypes: this.commTypeValues.map((el) => el.value),
          });
          this.$notify({
            title: 'SUCCESS',
            text: 'Successfully added contact!',
            type: 'success',
            duration: 5000,
          });
          this.fName = '';
          this.lName = '';
          this.email = '';
          this.phone = '';
          this.title = '';
          this.commTypeValues = [];
          this.actionRequired = true;
          this.notificationOnly = false;
          this.contactTypeValue = null;
          this.emailTypeValue = null;
        }
      } else {
        this.$notify({
          title: 'ERROR',
          text: 'Please properly fill in all required fields!',
          type: 'error',
          duration: 5000,
        });
      }
    },
    editContact(contact: IContact) {
      this.editingContact = true;
      this.contactBeingEdited = contact;
      this.fName = contact?.firstName ?? '';
      this.lName = contact?.lastName ?? '';
      this.email = contact.email?.trim();
      this.title = contact?.title ?? '';
      this.phone = contact?.phoneNumber ?? '';
      this.actionRequired = contact?.actionRequired === true || contact?.actionRequired === false ? contact?.actionRequired : true;
      this.notificationOnly = contact?.notificationOnly === true || contact?.notificationOnly === false ? contact?.notificationOnly : false;
      this.contactTypes.forEach((type: IDropdownOption) => {
        if (contact.contactTypeId === type.value) {
          this.contactTypeValue = type;
        }
      });
      this.emailTypes.forEach((type: IDropdownOption) => {
        if (contact.emailType === type.value) {
          this.emailTypeValue = type;
        }
      });
      let ct: IDropdownOption[] = [];
      this.communicationTypes.forEach((type: IDropdownOption) => {
        if (contact.communicationTypes) {
          contact.communicationTypes.forEach((t: any) => {
            if (typeof t === 'object') {
              if (type.value === t.communicationTypeId) {
                ct.push(type);
              }
            }
            if (typeof t === 'number') {
              if (type.value === t) {
                ct.push(type);
              }
            }
          });
        }
      });
      this.commTypeValues = ct;
    },
    cancelEdit() {
      this.editingContact = false;
      this.contactBeingEdited = null;
      this.fName = '';
      this.lName = '';
      this.title = '';
      this.email = '';
      this.phone = '';
      this.commTypeValues = [];
      this.actionRequired = true;
      this.notificationOnly = false;
      this.contactTypeValue = null;
      this.emailTypeValue = null;
    },
    saveEdit() {
      if (this.email && this.contactTypeValue && this.emailTypeValue && this.formData?.controls?.e?.errors?.status === 'success') {
        this.contacts.forEach((item: IContact) => {
          if (item.email?.trim() === this.contactBeingEdited.email?.trim()) {
            item.firstName = this.fName;
            item.lastName = this.lName;
            item.email = this.email?.trim();
            item.title = this.title;
            item.phoneNumber = this.phone;
            item.communicationTypes = this.commTypeValues.map((el) => el.value);
            item.actionRequired = this.actionRequired;
            item.notificationOnly = this.notificationOnly;
            item.contactTypeId = this.contactTypeValue?.value as number;
            item.emailType = this.emailTypeValue?.value as string;
          }
        });
        this.contactBeingEdited = {};
        this.editingContact = false;
        this.fName = '';
        this.lName = '';
        this.email = '';
        this.phone = '';
        this.commTypeValues = [];
        this.title = '';
        this.actionRequired = true;
        this.notificationOnly = false;
        this.contactTypeValue = null;
        this.emailTypeValue = null;
      } else {
        this.$notify({
          title: 'ERROR',
          text: 'Please properly fill in all required fields!',
          type: 'error',
          duration: 5000,
        });
      }
    },
    deleteContact(contact: IContact) {
      this.contacts = this.contacts.filter((item) => item.email?.trim() !== contact.email?.trim());
      this.setChangesMade('delete contact');
    },
    toggleAppleBusiness() {
      this.appleBusiness = !this.appleBusiness;
      this.formData.controls.appleBusiness.value = !this.formData.controls.appleBusiness.value;
      if (this.appleBusiness) {
        this.formData.controls.streetAddress.validators = [Validators.required];
        this.formData.controls.city.validators = [Validators.required];
        this.formData.controls.country.validators = [Validators.required];
        if (this.countryValue?.value && this.provinces.length > 0) {
          this.formData.controls.province.validators = [Validators.required];
        } else {
          this.formData.controls.province.validators = [];
        }
        this.formData.controls.postalCode.validators = [Validators.required];
        this.formData.controls.appleCategories.validators = [Validators.required];
      } else {
        this.formData.controls.streetAddress.validators = [];
        this.formData.controls.city.validators = [];
        this.formData.controls.country.validators = [];
        this.formData.controls.province.validators = [];
        this.formData.controls.postalCode.validators = [];
        this.formData.controls.appleCategories.validators = [];
      }
      this.setChangesMade('APPLE BUSINESS');
    },
    closePrompt(): void {
      this.promptOpen = false;
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
    },
    deletePrompt(): void {
      this.openPrompt('delete');
    },
    async delete(): Promise<void> {
      this.deleting = true;
      this.promptOpen = false;

      try {
        await this.deleteStore(this.modalData?.row?.storeId);
        this.deleting = false;
        this.promptOpen = false;
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
          this.$notify({
            title: 'SUCCESS',
            text: 'Store deleted successfully!',
            type: 'success',
            duration: 5000,
          });
        }, 500);
      } catch (err) {
        this.deleting = false;
        this.promptOpen = false;
        this.$notify({
          title: 'ERROR',
          text: 'Unable to delete store.',
          type: 'error',
          duration: 5000,
        });
      }
    },
    async undelete(): Promise<void> {
      try {
        await this.undeleteStore(this.modalData?.row?.storeId);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.$notify({
            title: 'SUCCESS',
            text: 'Store undeleted successfully!',
            type: 'success',
            duration: 5000,
          });
        }, 500);
      } catch (err) {
        this.$notify({
          title: 'ERROR',
          text: 'Unable to undelete store.',
          type: 'error',
          duration: 5000,
        });
      }
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    async save(): Promise<void> {
      console.log(this.formData);
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors) {
        let payload = {
          languageId: this.formData?.controls?.languageId?.value,
          mallId: this.formData?.controls?.mall?.value,
          companyId: this.formData?.controls?.company?.value,
          brandId: this.formData?.controls?.brand?.value,
          storeNumber: this.formData?.controls?.storeNumber?.value,
          statusId: this.formData?.controls?.status?.value,
          openDate: this.formData?.controls?.openDate?.value,
          closeDate: this.formData?.controls?.closeDate?.value,
          phoneNumber: this.formData?.controls?.phoneNumber?.value,
          streetAddress: this.formData?.controls?.streetAddress?.value,
          city: this.formData?.controls?.city?.value,
          country: this.formData?.controls?.country?.value,
          province: this.formData?.controls?.province?.value,
          postalCode: this.formData?.controls?.postalCode?.value,
          tagIds: this.tagValues.map((tag) => tag.value),
          contacts: this.contacts,
          facebookUrl: this.formData?.controls?.facebookUrl?.value,
          twitterUrl: this.formData?.controls?.twitterUrl?.value,
          instagramUrl: this.formData?.controls?.instagramUrl?.value,
          storeIdentifier: this.formData?.controls?.storeIdentifier?.value,
          googleBusinessLocationId: this.formData?.controls?.googleBusinessLocationId?.value,
          appleBusiness: this.formData?.controls?.appleBusiness?.value,
          appleBusinessLocationId: this.formData?.controls?.appleBusinessLocationId?.value,
          appleCategories: this.appleCategoriesValues.map((appleCategory) => appleCategory.value),
        };
        this.saving = true;

        try {
          await this.saveStore(payload);
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'Store saved successfully!',
            type: 'success',
            duration: 5000,
          });
          this.changesMade = false;
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.saving = false;
          let errorText = 'Unable to create Store';
          if (err === 'Invalid email address') {
            errorText = 'Invalid email address in contacts';
          }
          this.$notify({
            title: 'ERROR',
            text: errorText,
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Store form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async update(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors) {
        this.contacts.forEach((contact: IContact) => {
          if (contact?.communicationTypes && contact?.communicationTypes.length > 0) {
            if (typeof contact?.communicationTypes[0] === 'object') {
              contact.communicationTypes = contact?.communicationTypes?.map((i) => i.communicationTypeId);
            }
          }
        });
        let payload = {
          languageId: this.formData?.controls?.languageId?.value,
          mallId: this.formData?.controls?.mall?.value,
          companyId: this.formData?.controls?.company?.value,
          brandId: this.formData?.controls?.brand?.value,
          storeNumber: this.formData?.controls?.storeNumber?.value,
          statusId: this.formData?.controls?.status?.value,
          openDate: this.formData?.controls?.openDate?.value,
          closeDate: this.formData?.controls?.closeDate?.value,
          phoneNumber: this.formData?.controls?.phoneNumber?.value,
          streetAddress: this.formData?.controls?.streetAddress?.value,
          city: this.formData?.controls?.city?.value,
          country: this.formData?.controls?.country?.value,
          province: this.formData?.controls?.province?.value,
          postalCode: this.formData?.controls?.postalCode?.value,
          tagIds: this.tagValues.map((tag) => tag.value),
          contacts: this.contacts,
          facebookUrl: this.formData?.controls?.facebookUrl?.value,
          twitterUrl: this.formData?.controls?.twitterUrl?.value,
          instagramUrl: this.formData?.controls?.instagramUrl?.value,
          storeIdentifier: this.formData?.controls?.storeIdentifier?.value,
          googleBusinessLocationId: this.formData?.controls?.googleBusinessLocationId?.value,
          appleBusiness: this.formData?.controls?.appleBusiness?.value,
          appleBusinessLocationId: this.formData?.controls?.appleBusinessLocationId?.value,
          appleCategories: this.appleCategoriesValues.map((appleCategory) => appleCategory.value),
        };
        this.saving = true;

        try {
          await this.updateStore({
            payload,
            storeId: this.modalData?.row?.storeId,
          });
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'Store updated successfully!',
            type: 'success',
            duration: 5000,
          });
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.saving = false;
          let errorText = 'Unable to update Store';
          if (err === 'Invalid email address') {
            errorText = 'Invalid email address in contacts';
          }
          this.$notify({
            title: 'ERROR',
            text: errorText,
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Store form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
  },
});
