/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDropdownOption } from '@/utilities/Interfaces/form.interfaces';
import { MetadataState, State } from '@vue/runtime-core';
import { ActionContext } from 'vuex';
import HttpService from '@/services/HttpService';
import { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/vue';
import { CommunicationTypes } from '@/enums/CommunicationTypes';
import captureExceptionInSentry from './errorHandler';
import { IMarketingCampaign } from '../../../../interfaces/src/v2';

let errorMessage: string;

interface DropdownOption {
  value: number | string;
  description: string;
}

interface DropdownOptionResponse {
  success: boolean;
  data: DropdownOption[];
}

interface Response {
  success: boolean;
  data: any;
}

export const MetadataModule = {
  state(): MetadataState {
    return {
      contactTypes: [],
      channels: [],
      contactGroups: [],
      communicationTypes: [],
      marketingCampaignTypes: [],
      jobTypes: [],
      languageOptions: [],
      mallTypes: [],
      storeStatuses: [],
      userRoles: [],
      userStatuses: [],
      companyStatuses: [],
      brandStatuses: [],
      countries: [],
      provinces: [],
      appleCategories: [],
      emailTemplates: [],
      invites: [],

      // DYNAMIC
      companyOptions: [],
      userOptions: [],
      primaryUserOptions: [],
      secondaryUsersOptions: [],
      ccUsersOptions: [],
      bccUsersOptions: [],
      brandOptions: [],
      brandCategories: [],
      mallOwners: [],
      brandTagOptions: [],
      availableCompanySpecificTags: [],
      marketingCampaignTagOptions: [],
      storeTagOptions: [],
      mallOptions: [],
      permissionTemplateOptions: [],
      storeOptions: [],
      memoSubtypeOptions: [],
    };
  },

  mutations: {
    SAVE_CONTACT_TYPES(state: MetadataState, contactTypes: DropdownOption[]): void {
      state.contactTypes = contactTypes;
    },

    SAVE_CHANNELS(state: MetadataState, channels: DropdownOption[]): void {
      state.channels = channels;
    },

    SAVE_CONTACT_GROUPS(state: MetadataState, contactGroups: DropdownOption[]): void {
      state.contactGroups = contactGroups;
    },

    SAVE_COMMUNICATION_TYPES(state: MetadataState, communicationTypes: DropdownOption[]): void {
      state.communicationTypes = communicationTypes;
    },

    SAVE_MARKETING_CAMPAIGN_TYPES(state: MetadataState, marketingCampaignTypes: DropdownOption[]): void {
      state.marketingCampaignTypes = marketingCampaignTypes;
    },

    SAVE_RECRUITMENT_CAMPAIGN_TYPES(state: MetadataState, recruitmentCampaignTypes: DropdownOption[]): void {
      state.jobTypes = recruitmentCampaignTypes;
    },

    SAVE_LANGUAGES(state: MetadataState, languages: DropdownOption[]): void {
      state.languageOptions = languages;
    },

    SAVE_MALL_TYPES(state: MetadataState, mallTypes: DropdownOption[]): void {
      state.mallTypes = mallTypes;
    },

    SAVE_STORE_STATUSES(state: MetadataState, storeStatuses: DropdownOption[]): void {
      state.storeStatuses = storeStatuses;
    },

    SAVE_USER_ROLES(state: MetadataState, userRoles: DropdownOption[]): void {
      state.userRoles = userRoles;
    },

    SAVE_USER_STATUSES(state: MetadataState, userStatuses: DropdownOption[]): void {
      state.userStatuses = userStatuses;
    },

    SAVE_COMPANY_STATUSES(state: MetadataState, companyStatuses: DropdownOption[]): void {
      state.companyStatuses = companyStatuses;
    },

    SAVE_BRAND_STATUSES(state: MetadataState, brandStatuses: DropdownOption[]): void {
      state.brandStatuses = brandStatuses;
    },

    SAVE_BRAND_CATEGORIES(state: MetadataState, brandCategories: DropdownOption[]): void {
      state.brandCategories = brandCategories;
    },

    SAVE_COUNTRIES(state: MetadataState, countries: DropdownOption[]): void {
      state.countries = countries;
    },

    SAVE_COMPANIES(state: MetadataState, companies: DropdownOption[]): void {
      state.companyOptions = companies;
    },

    SAVE_USERS(state: MetadataState, users: DropdownOption[]): void {
      state.userOptions = users;
    },

    SAVE_PRIMARY_USER(state: MetadataState, users: DropdownOption[]): void {
      state.primaryUserOptions = users;
    },

    SAVE_SECONDARY_USERS(state: MetadataState, users: DropdownOption[]): void {
      state.secondaryUsersOptions = users;
    },

    SAVE_CC_USERS(state: MetadataState, users: DropdownOption[]): void {
      state.ccUsersOptions = users;
    },

    SAVE_BCC_USERS(state: MetadataState, users: DropdownOption[]): void {
      state.bccUsersOptions = users;
    },

    SAVE_BRANDS(state: MetadataState, brands: DropdownOption[]): void {
      state.brandOptions = brands;
    },

    SAVE_MALL_OWNERS(state: MetadataState, mallOwners: DropdownOption[]): void {
      state.mallOwners = mallOwners;
    },

    SAVE_PROVINCES(state: MetadataState, provinces: DropdownOption[]): void {
      state.provinces = provinces;
    },

    SAVE_MARKETING_CAMPAIGN_TAGS(state: MetadataState, marketingCampaignTags: DropdownOption[]): void {
      state.marketingCampaignTagOptions = marketingCampaignTags;
    },

    SAVE_STORE_TAGS(state: MetadataState, storeTags: DropdownOption[]): void {
      state.storeTagOptions = storeTags;
    },

    SAVE_BRAND_TAGS(state: MetadataState, brandTags: DropdownOption[]): void {
      state.brandTagOptions = brandTags;
    },

    SAVE_COMPANY_TAGS(state: MetadataState, companyTags: DropdownOption[]): void {
      state.availableCompanySpecificTags = companyTags;
    },

    SAVE_MALL_OPTIONS(state: MetadataState, mallOptions: DropdownOption[]): void {
      state.mallOptions = mallOptions;
    },

    SAVE_STORE_OPTIONS(state: MetadataState, storeOptions: DropdownOption[]): void {
      state.storeOptions = storeOptions;
    },

    SAVE_PERMISSION_TEMPLATE_OPTIONS(state: MetadataState, permissionTemplateOptions: DropdownOption[]): void {
      state.permissionTemplateOptions = permissionTemplateOptions;
    },

    SAVE_MEMO_SUBTYPE_OPTIONS(state: MetadataState, memoSubtypes: DropdownOption[]): void {
      state.memoSubtypeOptions = memoSubtypes;
    },

    SAVE_APPLE_CATEGORIES(state: MetadataState, appleCategories: DropdownOption[]): void {
      state.appleCategories = appleCategories;
    },

    CLEAR_BRAND_TAGS(state: MetadataState): void {
      state.brandTagOptions = [];
    },

    CLEAR_COMPANY_TAGS(state: MetadataState): void {
      state.availableCompanySpecificTags = [];
    },

    CLEAR_MARKETING_CAMPAIGN_TAGS(state: MetadataState): void {
      state.marketingCampaignTagOptions = [];
    },

    CLEAR_STORE_TAGS(state: MetadataState): void {
      state.storeTagOptions = [];
    },

    SAVE_EMAIL_TEMPLATES(state: MetadataState, emailTemplates: any[]): void {
      state.emailTemplates = emailTemplates;
    },

    SAVE_GOOGLE_INVITES(state: MetadataState, invites: any[]): void {
      state.invites = invites;
    },

    CLEAR_METADATA(state: MetadataState): void {
      state.contactTypes = [];
      state.channels = [];
      state.contactGroups = [];
      state.communicationTypes = [];
      state.marketingCampaignTypes = [];
      state.jobTypes = [];
      state.languageOptions = [];
      state.mallTypes = [];
      state.storeStatuses = [];
      state.userRoles = [];
      state.userStatuses = [];
      state.companyStatuses = [];
      state.brandStatuses = [];
      state.brandCategories = [];
      state.countries = [];
      state.companyOptions = [];
      state.userOptions = [];
      state.primaryUserOptions = [];
      state.secondaryUsersOptions = [];
      state.ccUsersOptions = [];
      state.bccUsersOptions = [];
      state.brandOptions = [];
      state.mallOwners = [];
      state.provinces = [];
      state.brandTagOptions = [];
      state.availableCompanySpecificTags = [];
      state.marketingCampaignTagOptions = [];
      state.storeTagOptions = [];
      state.mallOptions = [];
      state.permissionTemplateOptions = [];
      state.storeOptions = [];
      state.memoSubtypeOptions = [];
      state.emailTemplates = [];
      state.invites = [];
    },
  },

  actions: {
    loadContactTypes(context: ActionContext<MetadataState, State>, type?: string): void {
      new HttpService()
        .getMetaContactTypes(type ?? '')
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_CONTACT_TYPES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadChannels(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaChannels()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_CHANNELS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadContactGroups(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaContactGroups()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_CONTACT_GROUPS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadMallCommunicationTypes(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaCommunicationTypes()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          const filteredCommunicationTypes = results.data.data.filter(
            (communicationType: any) => communicationType.value !== CommunicationTypes['Store Memo'],
          );
          context.commit('SAVE_COMMUNICATION_TYPES', filteredCommunicationTypes);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadStoreCommunicationTypes(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaCommunicationTypes()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_COMMUNICATION_TYPES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadMarketingCampaignTypes(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaMarketingCampaignTypes()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_MARKETING_CAMPAIGN_TYPES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadRecruitmentCampaignTypes(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaRecruitmentCampaignTypes()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_RECRUITMENT_CAMPAIGN_TYPES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadLanguageOptions(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaLanguages()
        .then((results: AxiosResponse<DropdownOptionResponse | any>) => {
          context.commit('SAVE_LANGUAGES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    async loadGoogleInvites(context: ActionContext<MetadataState, State>): Promise<any> {
      new HttpService()
        .getGoogleInvites()
        .then((results: AxiosResponse<Response>) => {
          context.commit('SAVE_GOOGLE_INVITES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    async acceptGoogleInvite(
      context: ActionContext<MetadataState, State>,
      payload: {
        inviteId: string;
      },
    ): Promise<any> {
      try {
        const records: AxiosResponse<Response> = await new HttpService().acceptGoogleInvite(payload);
        context.dispatch('loadGoogleInvites');
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    async declineGoogleInvite(
      context: ActionContext<MetadataState, State>,
      payload: {
        inviteId: string;
      },
    ): Promise<any> {
      try {
        const records: AxiosResponse<Response> = await new HttpService().declineGoogleInvite(payload);
        context.dispatch('loadGoogleInvites');
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    async loadLanguages(context: ActionContext<MetadataState, State>): Promise<any> {
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMetaLanguages(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey === 'id' ? 'languageId' : payload?.sortKey,
          payload?.sortDirection,
        );

        context.commit('SAVE_LANGUAGES', records.data.data);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    async saveLanguage(context: ActionContext<MetadataState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveLanguage(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('loadLanguages');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    loadMallTypes(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaMallTypes()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_MALL_TYPES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadStoreStatuses(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaStoreStatuses()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_STORE_STATUSES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadUserRoles(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaUserRoles()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_USER_ROLES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadUserStatuses(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaUserStatuses()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_USER_STATUSES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadCompanyStatuses(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaCompanyStatuses()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_COMPANY_STATUSES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadBrandStatuses(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaBrandStatuses()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_BRAND_STATUSES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadBrandCategories(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaBrandCategories()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_BRAND_CATEGORIES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadCountryOptions(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaCountries()
        .then((results: AxiosResponse<DropdownOptionResponse | any>) => {
          context.commit('SAVE_COUNTRIES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    async loadCountries(context: ActionContext<MetadataState, State>): Promise<any> {
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMetaCountries(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey === 'id' ? 'countryId' : payload?.sortKey,
          payload?.sortDirection,
        );

        context.commit('SAVE_COUNTRIES', records.data.data);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    async saveCountry(context: ActionContext<MetadataState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveCountry(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('loadCountries');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    loadCompanies(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaCompanies()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_COMPANIES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadBrands(context: ActionContext<MetadataState, State>, payload: { companyId: string }): void {
      new HttpService()
        .getMetaBrands(payload?.companyId)
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_BRANDS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadUsers(context: ActionContext<MetadataState, State>, payload: { companyId: string; mallId: string; brandId: string, userId: string, admins: boolean }): void {
      new HttpService()
        .getMetaUsers(payload.companyId, payload.mallId, payload.brandId, payload.userId, payload.admins)
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_USERS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadPrimaryUser(context: ActionContext<MetadataState, State>, payload: { email: boolean }): void {
      new HttpService()
        .getMetaUsersByEmail(payload.email)
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_PRIMARY_USER', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadSecondaryUsers(context: ActionContext<MetadataState, State>, payload: { email: boolean }): void {
      new HttpService()
        .getMetaUsersByEmail(payload.email)
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_SECONDARY_USERS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadCcUsers(context: ActionContext<MetadataState, State>, payload: { companyId: string; excludeUserId: string }): void {
      new HttpService()
        .getMetaUsers(payload.companyId, '', '', '', false)
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          if (payload.excludeUserId) {
            results.data.data = results.data.data.filter((user: any) => user.value !== payload.excludeUserId);
          }
          context.commit('SAVE_CC_USERS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadBccUsers(context: ActionContext<MetadataState, State>, payload: { companyId: string; excludeUserId: string }): void {
      new HttpService()
        .getMetaUsers(payload.companyId, '', '', '', false)
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          if (payload.excludeUserId) {
            results.data.data = results.data.data.filter((user: any) => user.value !== payload.excludeUserId);
          }
          context.commit('SAVE_BCC_USERS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadMallOwners(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaMallOwners()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_MALL_OWNERS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadProvinceOptions(context: ActionContext<MetadataState, State>, countryId?: number): void {
      new HttpService()
        .getMetaProvinces(countryId ?? 2)
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_PROVINCES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    async loadProvinces(context: ActionContext<MetadataState, State>): Promise<any> {
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMetaProvinces(
          undefined,
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey === 'id' ? 'provinceId' : payload?.sortKey,
          payload?.sortDirection,
        );

        for (const province of records.data.data) {
          if (province.country) {
            province.countryDesc = province.country.description;
          }
        }

        context.commit('SAVE_PROVINCES', records.data.data);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    async saveProvince(context: ActionContext<MetadataState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveProvince(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('loadProvinces');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    loadBrandTags(context: ActionContext<MetadataState, State>, payload: { brandId: string }): void {
      new HttpService()
        .getTagOptions([payload.brandId])
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_BRAND_TAGS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadCompanyTags(context: ActionContext<MetadataState, State>, payload: { companyId: string }): void {
      new HttpService()
        .getMetaCompanyTags(payload.companyId)
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_COMPANY_TAGS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadMarketingCampaignTags(context: ActionContext<MetadataState, State>, payload: { brandIds: string[] }): void {
      new HttpService()
        .getTagOptions(payload.brandIds, 'marketing-campaign')
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_MARKETING_CAMPAIGN_TAGS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadStoreTags(context: ActionContext<MetadataState, State>, payload: { brandIds: string[] }): void {
      new HttpService()
        .getTagOptions(payload.brandIds, 'store')
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_STORE_TAGS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadMallOptions(context: ActionContext<MetadataState, State>, payload: { companyId?: string }): void {
      new HttpService()
        .getMetaMallOptions(payload?.companyId)
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_MALL_OPTIONS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadPermissionTemplateOptions(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaPermissionTemplateOptions()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_PERMISSION_TEMPLATE_OPTIONS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadStoresByMallId(context: ActionContext<MetadataState, State>, payload: { mallId: string }): void {
      new HttpService()
        .getMetaStoreOptionsByMallId(payload.mallId)
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_STORE_OPTIONS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadMemoSubtypeOptions(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaMemoSubtypeOptions()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_MEMO_SUBTYPE_OPTIONS', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    loadAppleCategories(context: ActionContext<MetadataState, State>): void {
      new HttpService()
        .getMetaAppleCategories()
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_APPLE_CATEGORIES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    clearBrandTags(context: ActionContext<MetadataState, State>): void {
      context.commit('CLEAR_BRAND_TAGS');
    },

    clearCompanyTags(context: ActionContext<MetadataState, State>): void {
      context.commit('CLEAR_COMPANY_TAGS');
    },

    clearMarketingCampaignTags(context: ActionContext<MetadataState, State>): void {
      context.commit('CLEAR_MARKETING_CAMPAIGN_TAGS');
    },

    clearStoreTags(context: ActionContext<MetadataState, State>): void {
      context.commit('CLEAR_STORE_TAGS');
    },

    async getEmailTemplates(context: ActionContext<MetadataState, State>, type: string): Promise<any> {
      new HttpService()
        .getEmailTemplates(type)
        .then((results: AxiosResponse<DropdownOptionResponse>) => {
          context.commit('SAVE_EMAIL_TEMPLATES', results.data.data);
        })
        .catch((err: any) => {
          errorMessage = `Error: ${err.message || 'Unknown error'}. `;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          throw new Error(`API ${err}`);
        });
    },

    async saveSystemEvent(context: ActionContext<MetadataState, State>, payload: any): Promise<any> {
      const records: AxiosResponse<any> = await new HttpService().saveSystemEvent(payload);
      return records?.data?.data;
    },

    async saveFollowUpEmailReminder(context: ActionContext<MetadataState, State>, payload: any): Promise<any> {
      const records: AxiosResponse<any> = await new HttpService().saveFollowUpEmailReminder(payload);
      return records?.data?.data;
    },

    async getCcComponent(
      context: ActionContext<MetadataState, State>,
      payload: { marketingCampaignId: string; mallId?: string; storeIds?: string[] },
    ): Promise<any> {
      const records: AxiosResponse<any> = await new HttpService().getCcComponent(payload.marketingCampaignId, payload.mallId, payload.storeIds);
      return records?.data?.data;
    },

    async getContextVariables(context: ActionContext<MetadataState, State>, payload: { context: string; subContext?: string }): Promise<any> {
      const records: AxiosResponse<any> = await new HttpService().getContextVariables(payload.context, payload.subContext);
      return records?.data?.data;
    },

    // async generateRetailerActivityReport(context: ActionContext<MetadataState, State>): Promise<any> {
    //   const response = await new HttpService().generateRetailerActivityReport();
    //   const url = URL.createObjectURL(
    //     new Blob([response.data], {
    //       type: "application/vnd.ms-excel"
    //     })
    //   );
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", "file.xlsx");
    //   document.body.appendChild(link);
    //   link.click();
    // },

    clearMetadata(context: ActionContext<MetadataState, State>): void {
      context.commit('CLEAR_METADATA');
    },

    async export(context: ActionContext<MetadataState, State>, payload: { title: string; schema: any; data: any }): Promise<any> {
      const records: AxiosResponse<any> = await new HttpService().export(payload.title, payload.schema, payload.data);
      return records;
    },
  },

  getters: {
    contactTypes(state: MetadataState): IDropdownOption[] {
      return state.contactTypes;
    },
    channels(state: MetadataState): IDropdownOption[] {
      return state.channels;
    },
    contactGroups(state: MetadataState): IDropdownOption[] {
      return state.contactGroups;
    },
    communicationTypes(state: MetadataState): IDropdownOption[] {
      return state.communicationTypes;
    },
    marketingCampaignTypes(state: MetadataState): IDropdownOption[] {
      return state.marketingCampaignTypes;
    },
    jobTypes(state: MetadataState): IDropdownOption[] {
      return state.jobTypes;
    },
    languageOptions(state: MetadataState): IDropdownOption[] {
      return state.languageOptions;
    },
    mallTypes(state: MetadataState): IDropdownOption[] {
      return state.mallTypes;
    },
    storeStatuses(state: MetadataState): IDropdownOption[] {
      return state.storeStatuses;
    },
    userRoles(state: MetadataState): IDropdownOption[] {
      return state.userRoles;
    },
    userStatuses(state: MetadataState): IDropdownOption[] {
      return state.userStatuses;
    },
    companyStatuses(state: MetadataState): IDropdownOption[] {
      return state.companyStatuses;
    },
    brandStatuses(state: MetadataState): IDropdownOption[] {
      return state.brandStatuses;
    },
    brandCategories(state: MetadataState): IDropdownOption[] {
      return state.brandCategories;
    },
    countries(state: MetadataState): IDropdownOption[] {
      return state.countries;
    },
    provinces(state: MetadataState): IDropdownOption[] {
      return state.provinces;
    },
    companyOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.companyOptions;
    },
    userOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.userOptions;
    },
    primaryUserOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.primaryUserOptions;
    },
    secondaryUsersOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.secondaryUsersOptions;
    },
    ccUsersOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.ccUsersOptions;
    },
    bccUsersOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.bccUsersOptions;
    },
    brandOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.brandOptions;
    },
    mallOwners(state: MetadataState): IDropdownOption[] | [] {
      return state.mallOwners;
    },
    permissionTemplateOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.permissionTemplateOptions;
    },
    brandTagOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.brandTagOptions;
    },
    availableCompanySpecificTags(state: MetadataState): IDropdownOption[] | [] {
      return state.availableCompanySpecificTags;
    },
    marketingCampaignTagOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.marketingCampaignTagOptions;
    },
    storeTagOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.storeTagOptions;
    },
    mallOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.mallOptions;
    },
    metaStoreOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.storeOptions;
    },
    memoSubtypeOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.memoSubtypeOptions;
    },
    appleCategoriesOptions(state: MetadataState): IDropdownOption[] | [] {
      return state.appleCategories;
    },
    metaEmailTemplates(state: MetadataState): any[] | [] {
      return state.emailTemplates;
    },
    googleInviteOptions(state: MetadataState): any[] | [] {
      return state.invites;
    },
  },
};
