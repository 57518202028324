
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Table from '../../../../Table/Table.vue';
import { Tabs } from '../../../../../../utilities/Types/navigation.types';
import PageControls from '../../../../Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../../../../utilities/Types/table.types';
import { IDropdownOption } from '../../../../../../utilities/Interfaces/form.interfaces';
import { IContact, IStore } from '../../../../../../../../../interfaces/src/v2';
import Modal from '../../../../../atoms/Modal/Modal.vue';
import Button from '../../../../../atoms/Button/Button.vue';
import TextArea from '../../../../../atoms/Text-Area/Text-Area.vue';
import { ICommunicationType } from '../../../../../../../../../interfaces/src/v2/CommunicationType';
import PaginationRecords from '../../../../../molecules/Pagination-Records/PaginationRecords.vue';
import { Status } from '../../../../../../enums/Status';
import axios from 'axios';
import { AbortHandler } from '@/services/AbortHandler';
import { send } from 'process';
import { ReminderType } from '@/enums/ReminderType';

export default defineComponent({
  components: {
    'arc-table': Table,
    'arc-page-controls': PageControls,
    'arc-modal': Modal,
    'arc-button': Button,
    'arc-text-area': TextArea,
    'pagination-records': PaginationRecords,
  },
  computed: {
    ...mapGetters([
      'modalData',
      'marketingCampaignReview',
      'loadingMarketingCampaignReview',
      'recruitmentCampaignReview',
      'loadingRecruitmentCampaignReview',
      'mallMemoReview',
      'loadingMallMemoReview',
      'storeMemoReview',
      'loadingStoreMemoReview',
      'declineReasons',
      'metaChannels',
      'contactChannels',
      'mallsChannels',
      'systemEmails',
      'user',
      'access',
      'permissions',
      'metaEmailTemplates',
    ]),
    isLoading(): boolean {
      return (
        this.loadingMarketingCampaignReview || this.loadingRecruitmentCampaignReview || this.loadingMallMemoReview || this.loadingStoreMemoReview
      );
    },
    reasons(): any[] {
      return this.declineReasons?.data;
    },
    metaChannelsData(): any[] {
      return this.metaChannels?.data;
    },
    contactChannelsData(): any[] {
      return this.contactChannels?.data;
    },
    mallsChannelsData(): any[] {
      return this.mallsChannels?.data;
    },
    communicationType(): string {
      let type = '';
      if (this.modalData.row.commType === 'marketing-campaign') {
        type = `Marketing Campaign`;
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        type = `Recruitment Campaign`;
      }
      if (this.modalData.row.commType === 'store-memo') {
        type = `Store Memo`;
        if (this.modalData.row.commMemoSubTypeText === 'Retailer') {
          type = `Retailer Memo`;
        }
      }
      if (this.modalData.row.commType === 'mall-memo') {
        type = `Mall Memo`;
      }
      return type;
    },
    tabs(): Tabs {
      return [
        {
          title: `SENT (${this.modalData?.row?.stats?.sent || 0})`,
          activeTabFlag: 'sent',
        },
        {
          title: `APPROVED (${this.modalData?.row?.stats?.approved || 0})`,
          activeTabFlag: 'approved',
        },
        {
          title: `PENDING (${this.modalData?.row?.stats?.pending || 0})`,
          activeTabFlag: 'pending',
        },
        {
          title: `DECLINED (${this.modalData?.row?.stats?.declined || 0})`,
          activeTabFlag: 'declined',
        },
      ];
    },
    columnDefinitions(): ColumnDefinition[] {
      let coldefs: any = [];
      if (this.activeTab === 'sent') {
        coldefs = [
          {
            type: 'text',
            columnName: 'statusDesc',
            displayName: 'Status',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'mallDesc',
            displayName: 'Mall',
            headerStyle: 'min-width: 150px',
            sortable: true,
          },
          {
            type: 'website',
            columnName: 'mallUrl',
            displayName: 'Website',
          },
          {
            type: 'socials',
            columnName: 'socials',
            displayName: 'Socials',
          },
          {
            type: 'review',
            columnName: 'reviewUrl',
            displayName: 'Review Communication',
            headerStyle: 'min-width: 150px',
          },
          // {
          //   type: 'text',
          //   columnName: 'emailsSent',
          //   displayName: 'Emails Sent',
          //   sortable: true,
          // },
          {
            type: 'contact',
            columnName: 'primaryContact',
            displayName: 'Contact',
            headerStyle: 'min-width: 150px;',
          },
          {
            type: 'stringArray',
            columnName: 'cc',
            displayName: 'CC',
            headerStyle: 'min-width: 150px',
          },
          this.communicationType === 'Marketing Campaign'
            ? {
                type: 'ccComponent',
                columnName: 'ccComponent',
                displayName: 'Sent to',
                headerStyle: 'min-width: 200px',
              }
            : {},
          {
            type: 'emails',
            columnName: 'storeEmailsCount',
            displayName: 'Number of Emails Sent',
          },
        ];
      }
      if (this.activeTab === 'approved') {
        coldefs = [
          {
            type: 'text',
            columnName: 'mallDesc',
            displayName: 'Mall',
            headerStyle: 'min-width: 150px;',
            sortable: true,
          },
          {
            type: 'website',
            columnName: 'mallUrl',
            displayName: 'Website',
            headerStyle: 'min-width: 150px',
          },
          {
            type: 'socials',
            columnName: 'socials',
            displayName: 'Socials',
          },
          {
            type: 'review',
            columnName: 'reviewUrl',
            displayName: 'Review Communication',
            headerStyle: 'min-width: 150px',
          },
          // {
          //   type: 'text',
          //   columnName: 'emailsSent',
          //   displayName: 'Emails Sent',
          //   sortable: true,
          // },
          {
            type: 'contact',
            columnName: 'primaryContact',
            displayName: 'Contact',
            headerStyle: 'min-width: 150px;',
          },
          {
            type: 'stringArray',
            columnName: 'cc',
            displayName: 'CC',
            headerStyle: 'min-width: 150px',
          },
          {
            type: 'date',
            columnName: 'dateCampaignSent',
            displayName: 'Date Campaign Sent',
          },
          {
            type: 'date',
            columnName: 'actionDate',
            displayName: 'Date Accepted',
          },
          {
            type: 'text',
            columnName: 'responseTime',
            displayName: 'Response Time in Hours',
          },
          this.communicationType === 'Marketing Campaign' || this.communicationType === 'Recruitment Campaign'
            ? {
                type: 'approvedChannels',
                columnName: 'parsedChannels',
                displayName: 'Approved Channels',
                headerStyle: 'min-width: 500px',
              }
            : {},
          {
            type: 'emails',
            columnName: 'storeEmailsCount',
            displayName: 'Number of Emails Sent',
          },
        ];
      }
      if (this.activeTab === 'pending') {
        coldefs = [
          {
            type: 'text',
            columnName: 'mallDesc',
            displayName: 'Mall',
            headerStyle: 'min-width: 150px',
            sortable: true,
          },
          {
            type: 'website',
            columnName: 'mallUrl',
            displayName: 'Website',
          },
          {
            type: 'socials',
            columnName: 'socials',
            displayName: 'Socials',
          },
          {
            type: 'review',
            columnName: 'reviewUrl',
            displayName: 'Review Communication',
            headerStyle: 'min-width: 150px',
          },
          // {
          //   type: 'text',
          //   columnName: 'emailsSent',
          //   displayName: 'Emails Sent',
          //   sortable: true,
          // },
          {
            type: 'contact',
            columnName: 'primaryContact',
            displayName: 'Contact',
            headerStyle: 'min-width: 150px;',
          },
          {
            type: 'stringArray',
            columnName: 'cc',
            displayName: 'CC',
            headerStyle: 'min-width: 150px',
          },
          {
            type: 'emails',
            columnName: 'storeEmailsCount',
            displayName: 'Number of Emails Sent',
          },
        ];
      }
      if (this.activeTab === 'declined') {
        coldefs = [
          {
            type: 'text',
            columnName: 'mallDesc',
            displayName: 'Mall',
            headerStyle: 'min-width: 150px;',
            sortable: true,
          },
          {
            type: 'website',
            columnName: 'mallUrl',
            displayName: 'Website',
          },
          {
            type: 'socials',
            columnName: 'socials',
            displayName: 'Socials',
          },
          {
            type: 'review',
            columnName: 'reviewUrl',
            displayName: 'Review Communication',
            headerStyle: 'min-width: 150px',
          },
          // {
          //   type: 'text',
          //   columnName: 'emailsSent',
          //   displayName: 'Emails Sent',
          //   sortable: true,
          // },
          {
            type: 'contact',
            columnName: 'primaryContact',
            displayName: 'Contact',
            headerStyle: 'min-width: 150px;',
          },
          {
            type: 'stringArray',
            columnName: 'cc',
            displayName: 'CC',
            headerStyle: 'min-width: 150px',
          },
          {
            type: 'date',
            columnName: 'dateCampaignSent',
            displayName: 'Date Campaign Sent',
          },
          {
            type: 'date',
            columnName: 'actionDate',
            displayName: 'Action Date',
          },
          {
            type: 'text',
            columnName: 'reason',
            displayName: 'Reason',
          },
          {
            type: 'text',
            columnName: 'responseTime',
            displayName: 'Response Time in Hours',
          },
          {
            type: 'emails',
            columnName: 'storeEmailsCount',
            displayName: 'Number of Emails Sent',
          },
        ];
      }
      return coldefs;
    },
    emailColumnDefinitions(): ColumnDefinition[] {
      return [
        {
          type: 'urlText',
          columnName: 'emailSubjectWithUrl',
          displayName: 'Subject',
        },
        {
          type: 'text',
          columnName: 'toEmailAddress',
          displayName: 'To',
        },
        {
          type: 'stringArray',
          columnName: 'ccEmailAddresses',
          displayName: 'CC',
        },
        {
          type: 'sendGridStatus',
          columnName: 'sendGridStatus',
          displayName: 'Status',
        },
        {
          type: 'text',
          columnName: 'sendGridMessageId',
          displayName: 'TrackingId',
        },
        {
          type: 'timestamp',
          columnName: 'deliveredTimestamp',
          displayName: 'Date Sent',
          headerStyle: 'min-width: 150px',
        },
        {
          type: 'timestamp',
          columnName: 'lastOpenedTimestamp',
          displayName: 'Last Date Opened',
          headerStyle: 'min-width: 150px',
        },
        {
          type: 'text',
          columnName: 'openCount',
          displayName: 'Total Number of Opens',
          headerStyle: 'min-width: 140px',
          position: 'center',
          tooltip: 'This might not be 100% accurate as some email clients block tracking pixels.',
          tooltipStyles: 'bottom: -6rem; width: 180px',
        },
        {
          type: 'timestamp',
          columnName: 'lastClickedTimestamp',
          displayName: 'Last Date Clicked',
          headerStyle: 'min-width: 150px',
        },
        {
          type: 'text',
          columnName: 'clickCount',
          displayName: 'Total Number of Clicks',
        },
      ];
    },
    isAdmin(): boolean {
      return this.permissions?.superAdminAccess;
    },
    tableData(): any {
      this.startLoading();
      let data: any = [];
      let dateCampaignSent: Date;
      if (this.modalData?.row?.commType === 'marketing-campaign') {
        data = this.marketingCampaignReview?.stores;
      }
      if (this.modalData?.row?.commType === 'recruitment-campaign') {
        data = this.recruitmentCampaignReview?.stores;
        dateCampaignSent = this.recruitmentCampaignReview?.dateCampaignSent;
      }
      if (this.modalData?.row?.commType === 'mall-memo') {
        data = this.mallMemoReview?.data?.stores;
        dateCampaignSent = this.mallMemoReview?.data?.dateCampaignSent;
      }
      if (this.modalData?.row?.commType === 'store-memo') {
        data = this.storeMemoReview?.data?.stores;
        dateCampaignSent = this.storeMemoReview?.data?.dateCampaignSent;
      }
      if (data && data?.length > 0) {
        data?.forEach((store: IStore) => {
          let statusDesc = '';
          if (this.modalData?.type === 'approval') {
            if (store?.statuses?.currentStatusId === Status.Modified) {
              statusDesc = 'Removed';
            } else if (store?.statuses?.currentStatusId === Status.Deleted) {
              statusDesc = 'Deleted';
            } else if (store?.statuses?.statusId === Status.Approved || store?.statuses?.socialStatusId === Status.Approved) {
              statusDesc = 'Approved';
            } else if (
              store?.statuses?.statusId !== Status.Approved &&
              store?.statuses?.socialStatusId !== Status.Approved &&
              store?.statuses?.statusId !== Status.Declined &&
              store?.statuses?.socialStatusId !== Status.Declined
            ) {
              statusDesc = 'Pending';
            } else if (store?.statuses?.statusId === Status.Declined || store?.statuses?.socialStatusId === Status.Declined) {
              statusDesc = 'Declined';
            }
          }

          store.mallDesc = `${store.mall?.description}${store.storeIdentifier ? ` (${store.storeIdentifier})` : ''}`;
          store.mallUrl = store.mall?.promoUrl;
          store.socials = {
            twitterUrl: store?.mall?.twitterUrl,
            facebookUrl: store?.mall?.facebookUrl,
            instagramUrl: store?.mall?.instagramUrl,
          };
          store.statusDesc = statusDesc;
          store.dateCampaignSent = store?.statuses?.sendDate ?? dateCampaignSent ?? '';
          store.actionDate = store?.statuses?.actionedDate ?? store?.statuses?.updatedAt ?? '';
          store.reason = store?.statuses?.reasonForDeclineDescription ?? '';
          store.responseTime = store?.mall?.stats?.averageResponseTime ?? '';
          store.channels = store?.statuses?.channels;
          store.parsedChannels = store?.statuses?.parsedChannels;

          if (this.modalData?.row?.commType === 'marketing-campaign') {
            store.reviewUrl = `${window.location.protocol}//${window.location.host}/review/marketing-campaign?storeId=${store?.storeId}&marketingCampaignId=${store?.statuses?.marketingCampaignId}`;
          }
          if (this.modalData?.row?.commType === 'recruitment-campaign') {
            store.reviewUrl = `${window.location.protocol}//${window.location.host}/review/recruitment-campaign?storeId=${store?.storeId}&recruitmentCampaignId=${store?.statuses?.recruitmentCampaignId}`;
          }
          if (this.modalData?.row?.commType === 'mall-memo') {
            store.reviewUrl = `${window.location.protocol}//${window.location.host}/review/mall-memo?storeId=${store?.storeId}&memoId=${store?.statuses?.memoId}`;
          }
          if (this.modalData?.row?.commType === 'store-memo') {
            store.reviewUrl = `${window.location.protocol}//${window.location.host}/review/store-memo?storeId=${store?.storeId}&memoId=${store?.statuses?.memoId}`;
          }
          let primaryContact: any;
          const contactList: any[] = [];
          if (this.modalData?.row?.commType === 'store-memo') {
            if (store?.contacts && store?.contacts?.length > 0) {
              store?.contacts.forEach((contact: IContact) => {
                if (contact?.emailType === 'to') {
                  if (primaryContact) {
                    contactList.push(contact.email);
                  } else {
                    primaryContact = contact;
                  }
                } else {
                  contactList.push(contact.email);
                }
              });
            }
          } else {
            if (store?.mall?.contacts && store?.mall?.contacts?.length > 0) {
              let supportsSocial = 0;
              store?.mall?.contacts.forEach((contact: IContact) => {
                let validContact = false;
                let validSocial = false;
                contact?.communicationTypes?.forEach((type: ICommunicationType) => {
                  if (this.modalData?.row?.commType === 'marketing-campaign' && this.modalData?.type === 'approval') {
                    if (
                      type?.communicationTypeId === 1 ||
                      type?.communicationTypeId === 2 ||
                      type?.communicationTypeId === 3 ||
                      type?.communicationTypeId === 5
                    ) {
                      validContact = true;
                    }
                  }
                  if (this.modalData?.row?.commType === 'recruitment-campaign' && this.modalData?.type === 'approval') {
                    if (type?.communicationTypeId === 6) {
                      validContact = true;
                    }
                  }
                  if (this.modalData?.row?.commType === 'mall-memo') {
                    if (type?.communicationTypeId === 7) {
                      validContact = true;
                    }
                  }
                  if (this.modalData?.row?.commType === 'store-memo') {
                    validContact = true;
                  }
                  if (type?.communicationTypeId === 4) {
                    supportsSocial = 1;
                  }
                });
                if (validContact) {
                  if (this.modalData?.type === 'approval' || validSocial) {
                    if (contact?.emailType === 'to') {
                      if (primaryContact) {
                        contactList.push(contact.email);
                      } else {
                        primaryContact = contact;
                      }
                    } else {
                      contactList.push(contact.email);
                    }
                  }
                }
              });
              store.supportsSocial = supportsSocial;
            }
          }
          store.primaryContact = primaryContact;
          store.cc = contactList.filter((email) => email !== store?.primaryContact?.email);
        });
      }

      // ADD FILTER BY TAB
      if (this.activeTab === 'approved') {
        data = data?.filter(
          (communication: any) =>
            communication?.statuses?.statusId === Status.Approved || communication?.statuses?.socialStatusId === Status.Approved,
        );
      }
      if (this.activeTab === 'pending') {
        data = data?.filter(
          (communication: any) =>
            !(communication?.statuses?.statusId === Status.Approved || communication?.statuses?.socialStatusId === Status.Approved) &&
            !(communication?.statuses?.statusId === Status.Declined || communication?.statuses?.socialStatusId === Status.Declined),
        );
      }
      if (this.activeTab === 'declined') {
        data = data?.filter(
          (communication: any) =>
            !(communication?.statuses?.statusId === Status.Approved || communication?.statuses?.socialStatusId === Status.Approved) &&
            (communication?.statuses?.statusId === Status.Declined || communication?.statuses?.socialStatusId === Status.Declined),
        );
      }

      // filter by search string
      if (this.searchFilter !== '') {
        let searchResults: any[] = [];
        data.forEach((element: any) => {
          let matchFound = false;
          if (typeof Object.values(element) === 'string') {
            if (Object.values(element).includes(this.searchFilter)) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'number') {
            if (
              Object.values(element)
                .toString()
                .includes(this.searchFilter)
            ) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'object' && Object.values(element)) {
            Object.values(element)
              .filter((value) => value)
              .forEach((nestedElement: any) => {
                if (typeof nestedElement === 'string') {
                  if (nestedElement.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'number') {
                  if (nestedElement.toString().includes(this.searchFilter)) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'object' && nestedElement) {
                  Object.values(nestedElement)
                    .filter((value) => value)
                    .forEach((nestedElementL2: any) => {
                      if (typeof nestedElementL2 === 'string') {
                        if (nestedElementL2.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'number') {
                        if (nestedElementL2.toString().includes(this.searchFilter)) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'object' && nestedElementL2) {
                        Object.values(nestedElementL2)
                          .filter((value) => value)
                          .forEach((nestedElementL3: any) => {
                            if (typeof nestedElementL3 === 'string') {
                              if (nestedElementL3.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                                matchFound = true;
                              }
                            }
                            if (typeof nestedElementL3 === 'number') {
                              if (nestedElementL3.toString().includes(this.searchFilter)) {
                                matchFound = true;
                              }
                            }
                          });
                      }
                    });
                }
              });
          }
          if (matchFound) {
            searchResults.push(element);
          }
        });
        data = searchResults;
      }
      // sorting
      if (data && data?.length > 0) {
        if (this.sortDefinition.direction === 'ASC') {
          if (this.sortDefinition.type === 'text') {
            data.sort((a: any, b: any) =>
              a[this.sortDefinition.column] > b[this.sortDefinition.column]
                ? 1
                : b[this.sortDefinition.column] > a[this.sortDefinition.column]
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'date') {
            data.sort((a: any, b: any) =>
              new Date(a[this.sortDefinition.column]) > new Date(b[this.sortDefinition.column])
                ? 1
                : new Date(b[this.sortDefinition.column]) > new Date(a[this.sortDefinition.column])
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'approval') {
            data.sort((a: any, b: any) =>
              a[this.sortDefinition.column].percentage > b[this.sortDefinition.column].percentage
                ? 1
                : b[this.sortDefinition.column].percentage > a[this.sortDefinition.column].percentage
                ? -1
                : 0,
            );
          }
        } else {
          if (this.sortDefinition.type === 'text') {
            data.sort((a: any, b: any) =>
              a[this.sortDefinition.column] < b[this.sortDefinition.column]
                ? 1
                : b[this.sortDefinition.column] < a[this.sortDefinition.column]
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'date') {
            data.sort((a: any, b: any) =>
              new Date(a[this.sortDefinition.column]) < new Date(b[this.sortDefinition.column])
                ? 1
                : new Date(b[this.sortDefinition.column]) < new Date(a[this.sortDefinition.column])
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'approval') {
            data.sort((a: any, b: any) =>
              a[this.sortDefinition.column].percentage < b[this.sortDefinition.column].percentage
                ? 1
                : b[this.sortDefinition.column].percentage < a[this.sortDefinition.column].percentage
                ? -1
                : 0,
            );
          }
        }
        // update all table data available for pagination to use
        this.updateAllTableData(data);
        // pagination
        let pageData: any[] = [];
        let startingPoint = 0;
        let pageValue: any = this.pageValue;
        let showValue: any = this.showValue;
        if (pageValue.value > 1) {
          startingPoint = showValue.value * (pageValue.value - 1);
        }
        for (let i = startingPoint; i < startingPoint + showValue.value && i < this.allTableData.length; i++) {
          pageData.push(data[i]);
        }
        data = pageData;
      }
      this.finishLoading();
      if (data && data.length > 0) {
        this.resetCheckboxes(data);
      }
      return data;
    },
    getCurrentDataTotal(): any {
      let data: any = [];
      if (this.modalData.row.commType === 'marketing-campaign') {
        data = this.marketingCampaignReview?.stores;
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        data = this.recruitmentCampaignReview?.stores;
      }
      if (this.modalData.row.commType === 'mall-memo') {
        data = this.mallMemoReview?.data?.stores;
      }
      if (this.modalData.row.commType === 'store-memo') {
        data = this.storeMemoReview?.data?.stores;
      }
      if (data && data?.length > 0) {
        data?.forEach((store: IStore) => {
          let statusDesc = '';
          if (this.modalData?.type === 'approval') {
            if (store?.statuses?.currentStatusId === Status.Modified) {
              statusDesc = 'Removed';
            } else if (store?.statuses?.currentStatusId === Status.Deleted) {
              statusDesc = 'Deleted';
            } else if (store?.statuses?.statusId === Status.Approved || store?.statuses?.socialStatusId === Status.Approved) {
              statusDesc = 'Approved';
            } else if (
              store?.statuses?.statusId !== Status.Approved &&
              store?.statuses?.socialStatusId !== Status.Approved &&
              store?.statuses?.statusId !== Status.Declined &&
              store?.statuses?.socialStatusId !== Status.Declined
            ) {
              statusDesc = 'Pending';
            } else if (store?.statuses?.statusId === Status.Declined || store?.statuses?.socialStatusId === Status.Declined) {
              statusDesc = 'Declined';
            }
          }

          store.mallDesc = `${store.mall?.description}${store.storeIdentifier ? ` (${store.storeIdentifier})` : ''}`;
          store.mallUrl = store.mall?.promoUrl;
          store.statusDesc = statusDesc;
          store.actionDate = store?.statuses?.actionedDate ?? store?.statuses?.updatedAt ?? '';
          store.reason = store?.statuses?.reasonForDeclineDescription ?? '';
          store.responseTime = store?.mall?.stats?.averageResponseTime ?? '';
          store.channels = this.metaChannelsData
            .filter((channel: { value: number; description: string }) => store?.statuses?.channels?.includes(channel.value))
            .map((channel: { value: number; description: string }) => channel.description);

          if (this.modalData.row.commType === 'marketing-campaign') {
            store.reviewUrl = `${window.location.protocol}//${window.location.host}/review/marketing-campaign?storeId=${store?.storeId}&marketingCampaignId=${store?.statuses?.marketingCampaignId}`;
          }
          if (this.modalData.row.commType === 'recruitment-campaign') {
            store.reviewUrl = `${window.location.protocol}//${window.location.host}/review/recruitment-campaign?storeId=${store?.storeId}&recruitmentCampaignId=${store?.statuses?.recruitmentCampaignId}`;
          }
          if (this.modalData.row.commType === 'mall-memo') {
            store.reviewUrl = `${window.location.protocol}//${window.location.host}/review/mall-memo?storeId=${store?.storeId}&memoId=${store?.statuses?.memoId}`;
          }
          if (this.modalData.row.commType === 'store-memo') {
            store.reviewUrl = `${window.location.protocol}//${window.location.host}/review/store-memo?storeId=${store?.storeId}&memoId=${store?.statuses?.memoId}`;
          }
          let primaryContact: any;
          const contactList: any[] = [];
          if (this.modalData.row.commType === 'store-memo') {
            if (store?.contacts && store?.contacts?.length > 0) {
              store?.contacts.forEach((contact: IContact) => {
                if (contact?.emailType === 'to') {
                  if (primaryContact) {
                    contactList.push(contact.email);
                  } else {
                    primaryContact = contact;
                  }
                } else {
                  contactList.push(contact.email);
                }
              });
            }
          } else {
            if (store?.mall?.contacts && store?.mall?.contacts?.length > 0) {
              let supportsSocial = 0;
              store?.mall?.contacts.forEach((contact: IContact) => {
                let validContact = false;
                let validSocial = false;
                contact?.communicationTypes?.forEach((type: ICommunicationType) => {
                  if (this.modalData.row.commType === 'marketing-campaign' && this.modalData?.type === 'approval') {
                    if (
                      type?.communicationTypeId === 1 ||
                      type?.communicationTypeId === 2 ||
                      type?.communicationTypeId === 3 ||
                      type?.communicationTypeId === 5
                    ) {
                      validContact = true;
                    }
                  }
                  if (this.modalData.row.commType === 'recruitment-campaign' && this.modalData?.type === 'approval') {
                    if (type?.communicationTypeId === 6) {
                      validContact = true;
                    }
                  }
                  if (this.modalData.row.commType === 'mall-memo') {
                    if (type?.communicationTypeId === 7) {
                      validContact = true;
                    }
                  }
                  if (this.modalData.row.commType === 'store-memo') {
                    validContact = true;
                  }
                  if (type?.communicationTypeId === 4) {
                    supportsSocial = 1;
                  }
                });
                if (validContact) {
                  if (this.modalData?.type === 'approval' || validSocial) {
                    if (contact?.emailType === 'to') {
                      if (primaryContact) {
                        contactList.push(contact.email);
                      } else {
                        primaryContact = contact;
                      }
                    } else {
                      contactList.push(contact.email);
                    }
                  }
                }
              });
              store.supportsSocial = supportsSocial;
            }
          }
          store.primaryContact = primaryContact;
          store.cc = contactList.filter((email) => email !== store?.primaryContact?.email);
        });
      }

      // ADD FILTER BY TAB
      if (this.activeTab === 'approved') {
        data = data?.filter(
          (communication: any) =>
            communication?.statuses?.statusId === Status.Approved || communication?.statuses?.socialStatusId === Status.Approved,
        );
      }
      if (this.activeTab === 'pending') {
        data = data?.filter(
          (communication: any) =>
            !(communication?.statuses?.statusId === Status.Approved || communication?.statuses?.socialStatusId === Status.Approved) &&
            !(communication?.statuses?.statusId === Status.Declined || communication?.statuses?.socialStatusId === Status.Declined),
        );
      }
      if (this.activeTab === 'declined') {
        data = data?.filter(
          (communication: any) =>
            !(communication?.statuses?.statusId === Status.Approved || communication?.statuses?.socialStatusId === Status.Approved) &&
            (communication?.statuses?.statusId === Status.Declined || communication?.statuses?.socialStatusId === Status.Declined),
        );
      }

      return data?.length || 0;
    },
    tableOptions(): any {
      let options: any = {
        selectable: true,
        actions: true,
      };
      if (this.communicationType === 'Marketing Campaign' && (this.access?.marketingCampaignsAll || this.access?.marketingCampaignsCompany)) {
        options = {
          selectable: true,
          actions: true,
        };
      }
      if (this.communicationType === 'Recruitment Campaign' && (this.access?.recruitmentCampaignsAll || this.access?.recruitmentCampaignsCompany)) {
        options = {
          selectable: true,
          actions: true,
        };
      }
      if (this.communicationType === 'Mall Memo' && (this.access?.mallMemosAll || this.access?.mallMemosCompany)) {
        options = {
          selectable: true,
          actions: true,
        };
      }
      if (this.communicationType === 'Store Memo' && (this.access?.storeMemosAll || this.access?.storeMemosCompany)) {
        options = {
          selectable: true,
          actions: true,
        };
      }
      return options;
    },
    buttonOptions(): any {
      return {
        showCreate: false,
        showReports: false,
        showExport: true,
      };
    },
    pageOptions(): IDropdownOption[] {
      let tableData: any = this.allTableData;
      let showValue: any = this.showValue.value;
      let options = [];
      let numberOfPages = Math.ceil(tableData.length / showValue);
      for (let i = 0; i < numberOfPages; i++) {
        options.push({ description: `${i + 1}`, value: i + 1 });
      }
      return options;
    },
    totalPages(): number {
      let tableData: any = this.allTableData;
      let showValue: any = this.showValue.value;
      return Math.ceil(tableData.length / showValue);
    },
  },
  data(): {
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    allTableData: any;
    sortDefinition: SortDefinition;
    activeTab: string;
    checked: boolean[];
    allChecked: boolean;
    singleSelects: boolean[];
    selectedRows: any[];
    loading: boolean;
    loadingEmails: boolean;
    loadingCcComponents: boolean;
    showSelected: boolean;
    showMasterCheckbox: boolean;
    promptOpen: boolean;
    promptType: string;
    selectedReason: string;
    reasonMessage: string;
    reasonDescription: string;
    channels: any[];
    declinedObject: any;
    approvedObject: any;
    questionObject: any;
    declining: boolean;
    approving: boolean;
    campaignId: string;
    memoId: string;
    communication: any;
    submitting: boolean;
    buttonFeedback: {
      exportLoading: boolean;
      exportSubmitted: boolean;
    };
    mallLink: string;
    followUpEmailTemplate: any;
  } {
    return {
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
        { description: '100', value: 100 },
      ],
      pageValue: { description: '1', value: 1 },
      allTableData: [],
      sortDefinition: { column: 'commId', type: 'text', direction: 'DESC' },
      activeTab: 'sent',
      checked: [],
      allChecked: false,
      singleSelects: [],
      selectedRows: [],
      loading: true,
      loadingEmails: true,
      loadingCcComponents: true,
      showSelected: false,
      showMasterCheckbox: true,
      promptOpen: false,
      promptType: '',
      selectedReason: '',
      reasonMessage: '',
      reasonDescription: '',
      channels: [],
      declinedObject: null,
      approvedObject: null,
      questionObject: null,
      declining: false,
      approving: false,
      campaignId: '',
      memoId: '',
      communication: null,
      submitting: false,
      buttonFeedback: {
        exportLoading: false,
        exportSubmitted: false,
      },
      mallLink: '',
      followUpEmailTemplate: null,
    };
  },
  async beforeUnmount(): Promise<void> {
    const controller = AbortHandler.getInstance();
    controller.voidControllers();

    if ('marketingCampaignId' in this.communication) {
      this.fetchMarketingCampaigns();
      return;
    }

    if ('recruitmentCampaignId' in this.communication) {
      this.fetchRecruitmentCampaigns();
      return;
    }

    if ('memoId' in this.communication && 'memoTypeId' in this.communication && this.communication.memoTypeId === 2) {
      this.fetchMallMemos();
      return;
    }

    if ('memoId' in this.communication && 'memoTypeId' in this.communication && this.communication.memoTypeId === 1) {
      this.fetchStoreMemos();
      return;
    }
  },
  async mounted(): Promise<void> {
    this.fetchDeclineReasons();
    this.fetchChannels();
    // this.fetchContactChannels(this.user?.userId);
    this.activeTab = this.modalData.status;
    if (this.modalData?.row?.commType === 'marketing-campaign') {
      this.campaignId = this.modalData?.row?.marketingCampaignId;
      this.communication = await this.fetchMarketingCampaignReview({
        campaignId: this.campaignId,
        type: 'user',
        userId: this.user?.userId,
      });
    }
    if (this.modalData?.row?.commType === 'recruitment-campaign') {
      this.campaignId = this.modalData?.row?.recruitmentCampaignId;
      this.communication = await this.fetchRecruitmentCampaignReview({
        campaignId: this.campaignId,
        type: 'user',
        userId: this.user?.userId,
      });
    }
    if (this.modalData?.row?.commType === 'mall-memo') {
      this.memoId = this.modalData?.row?.memoId;
      this.communication = await this.fetchMallMemoReview({
        memoId: this.memoId,
        type: 'user',
        userId: this.user?.userId,
      });
      this.communication = this.communication.data;
    }
    if (this.modalData?.row?.commType === 'store-memo') {
      this.memoId = this.modalData?.row?.memoId;
      this.communication = await this.fetchStoreMemoReview({
        memoId: this.memoId,
        type: 'user',
        userId: this.user?.userId,
      });
      this.communication = this.communication.data;
    }
  },
  methods: {
    ...mapActions([
      'fetchMarketingCampaigns',
      'fetchRecruitmentCampaigns',
      'fetchMallMemos',
      'fetchStoreMemos',
      'fetchMarketingCampaignReview',
      'fetchRecruitmentCampaignReview',
      'fetchMallMemoReview',
      'fetchStoreMemoReview',
      'setMarketingCampaignReviewStoreStatus',
      'setRecruitmentCampaignReviewStoreStatus',
      'setMallMemoReviewStoreStatus',
      'setStoreMemoReviewStoreStatus',
      'fetchDeclineReasons',
      'fetchChannels',
      'fetchContactChannels',
      'fetchMallsChannels',
      'fetchSystemEmails',
      'getEmailTemplates',
      'saveFollowUpEmailReminder',
      'getCcComponent',
    ]),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    resetPageValue(): void {
      this.pageValue = { description: '1', value: 1 };
      this.loadingCcComponents = true;
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    updateShow(filter: IDropdownOption) {
      this.resetPageValue();
      this.showValue = filter;
      this.loadingCcComponents = true;
    },
    updatePage(filter: IDropdownOption) {
      this.pageValue = filter;
      this.loadingCcComponents = true;
    },
    previousPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value - 1}`,
        value: pageValue.value - 1,
      };
      this.loadingCcComponents = true;
    },
    nextPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value + 1}`,
        value: pageValue.value + 1,
      };
      this.loadingCcComponents = true;
    },
    search(searchFilter: string): void {
      this.resetPageValue();
      this.searchFilter = searchFilter;
      this.loadingCcComponents = true;
    },
    sort(payload: SortDefinition) {
      this.resetPageValue();
      this.sortDefinition = payload;
      this.loadingCcComponents = true;
    },
    updateActiveTab(tab: string) {
      this.activeTab = tab;
      this.resetPageValue();
    },
    toggleAllCheckboxes(): void {
      this.allChecked = !this.allChecked;
      let i = 0;
      this.checked.forEach(() => {
        this.checked[i] = this.allChecked;
        this.singleSelects[i] = this.allChecked;
        this.selectedRows = this.allChecked ? this.tableData : [];
        i++;
      });
    },
    resetCheckboxes(data: any): void {
      this.checked = [];
      this.singleSelects = [];
      this.allChecked = false;
      data.forEach(() => {
        this.checked.push(false);
        this.singleSelects.push(false);
      });
      this.selectedRows = [];
      this.showMasterCheckbox = false;
      setTimeout(() => {
        this.showMasterCheckbox = true;
      }, 50);
    },
    checkboxClicked(index: any): void {
      this.singleSelects[index] = !this.singleSelects[index];
    },
    async submitQuestions(): Promise<void> {
      let allQuestionsAnswered = true;
      this.communication?.questions?.forEach((question: any) => {
        if (question?.answer === '') {
          allQuestionsAnswered = false;
        }
      });
      if (allQuestionsAnswered) {
        this.submitting = true;
        this.questionObject.answers = this.communication?.questions;
        if (this.modalData.row.commType === 'mall-memo') {
          await this.setMallMemoReviewStoreStatus(this.questionObject);
          let review = await this.fetchMallMemoReview({
            memoId: this.memoId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
        if (this.modalData.row.commType === 'store-memo') {
          await this.setStoreMemoReviewStoreStatus(this.questionObject);
          let review = await this.fetchStoreMemoReview({
            memoId: this.memoId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
        this.questionObject = null;
        this.submitting = false;
        this.closePrompt();
        this.fetchMarketingCampaigns();
        this.fetchRecruitmentCampaigns();
        this.fetchMallMemos();
        this.fetchStoreMemos();
      } else {
        this.$notify({
          title: 'WARNING',
          text: 'Please answer all questions to submit',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    anyChecked() {
      let anychecked = false;
      this.singleSelects?.forEach((check: any) => {
        if (check) {
          anychecked = true;
        }
      });
      return anychecked;
    },
    async editChannels(event: any) {
      let obj: any = {
        campaignId: this.campaignId,
        storeIds: [event.storeId],
        reasonForDeclineDescription: '',
      };
      obj.statusId = 8;
      this.approvedObject = obj;
      this.mallLink = `${window.location.protocol}//${window.location.host}/review/mall-details?mallId=${event.mallId}`;
      await this.fetchMallsChannelsHelper([event.storeId]);
      this.channels = [];
      this.mallsChannelsData.forEach((channel: any) => {
        if (event?.statusId === Status.Approved || event?.socialStatusId === Status.Approved) {
          let channels = event?.channels ?? [];
          let lastArray = Array.isArray(channels) && channels.length > 0 ? channels[channels.length - 1] : [];
          if (lastArray.map((el: any) => el.channelId)?.includes(channel?.value)) {
            this.channels.push(channel?.value);
          }
        }
      });
      this.openPrompt('accept');
    },
    async actionClicked(event: any): Promise<void> {
      if (this.modalData.row.commType === 'marketing-campaign') {
        let obj: any = {
          campaignId: this.campaignId,
          storeIds: [event.row.storeId],
          reasonForDeclineDescription: '',
        };
        if (event.type === 'accepted') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 8;
            obj.socialStatusId = 8;
          }
          this.approvedObject = obj;
          this.mallLink = `${window.location.protocol}//${window.location.host}/review/mall-details?mallId=${event.row.mallId}`;
          await this.fetchMallsChannelsHelper([event.row.storeId]);
          this.openPrompt('accept');
        }
        if (event.type === 'reset') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 16;
            obj.socialStatusId = 16;
          }
          await this.setMarketingCampaignReviewStoreStatus(obj);
          let review = await this.fetchMarketingCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
        if (event.type === 'declined') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 9;
            obj.socialStatusId = 9;
          }
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
        if (event.type === 'confirmDelete') {
          obj.currentStatusId = Status.Deleted;
          await this.setMarketingCampaignReviewStoreStatus(obj);
          let review = await this.fetchMarketingCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        let obj: any = {
          campaignId: this.campaignId,
          storeIds: [event.row.storeId],
          reasonForDeclineDescription: '',
        };
        if (event.type === 'accepted') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 8;
            obj.socialStatusId = 8;
          }
          this.approvedObject = obj;
          this.mallLink = `${window.location.protocol}//${window.location.host}/review/mall-details?mallId=${event.row.mallId}`;
          await this.fetchMallsChannelsHelper([event.row.storeId]);
          this.openPrompt('accept');
        }
        if (event.type === 'reset') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 16;
            obj.socialStatusId = 16;
          }
          await this.setRecruitmentCampaignReviewStoreStatus(obj);
          let review = await this.fetchRecruitmentCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
        if (event.type === 'declined') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 9;
            obj.socialStatusId = 9;
          }
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
        if (event.type === 'confirmDelete') {
          obj.currentStatusId = Status.Deleted;
          await this.setRecruitmentCampaignReviewStoreStatus(obj);
          let review = await this.fetchRecruitmentCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
      }
      if (this.modalData.row.commType === 'mall-memo') {
        let obj: any = {
          memoId: this.memoId,
          storeIds: [event.row.storeId],
          reasonForDeclineDescription: '',
        };
        if (event.type === 'accepted') {
          obj.statusId = 8;
          if (this.communication?.questions && this.communication?.questions?.length > 0) {
            obj.answers = [];
            this.communication?.questions?.forEach((question: any) => {
              question.answer = '';
              question.answers = [];
              question.answers[0] = {
                memoQuestionId: question?.memoQuestionId,
                description: '',
                userId: this.user?.userId,
              };
            });
            this.questionObject = obj;
            this.openPrompt('question');
          } else {
            await this.setMallMemoReviewStoreStatus(obj);
            let review = await this.fetchMallMemoReview({
              memoId: this.memoId,
              type: 'user',
              userId: this.user?.userId,
            });
            this.updateTabCounts(review);
          }
        }
        if (event.type === 'reset') {
          obj.statusId = 16;
          await this.setMallMemoReviewStoreStatus(obj);
          let review = await this.fetchMallMemoReview({
            memoId: this.memoId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
        if (event.type === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
        if (event.type === 'confirmDelete') {
          obj.currentStatusId = Status.Deleted;
          await this.setMallMemoReviewStoreStatus(obj);
          let review = await this.fetchMallMemoReview({
            memoId: this.memoId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
      }
      if (this.modalData.row.commType === 'store-memo') {
        let obj: any = {
          memoId: this.memoId,
          storeIds: [event.row.storeId],
          reasonForDeclineDescription: '',
        };
        if (event.type === 'accepted') {
          obj.statusId = 8;
          if (this.communication?.questions && this.communication?.questions?.length > 0) {
            obj.answers = [];
            this.communication?.questions?.forEach((question: any) => {
              question.answer = '';
              question.answers = [];
              question.answers[0] = {
                memoQuestionId: question?.memoQuestionId,
                description: '',
                userId: this.user?.userId,
              };
            });
            this.questionObject = obj;
            this.openPrompt('question');
          } else {
            await this.setStoreMemoReviewStoreStatus(obj);
            let review = await this.fetchStoreMemoReview({
              memoId: this.memoId,
              type: 'user',
              userId: this.user?.userId,
            });
            this.updateTabCounts(review);
          }
        }
        if (event.type === 'reset') {
          obj.statusId = 16;
          await this.setStoreMemoReviewStoreStatus(obj);
          let review = await this.fetchStoreMemoReview({
            memoId: this.memoId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
        if (event.type === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
        if (event.type === 'confirmDelete') {
          obj.currentStatusId = Status.Deleted;
          await this.setStoreMemoReviewStoreStatus(obj);
          let review = await this.fetchStoreMemoReview({
            memoId: this.memoId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
      }
      this.fetchMarketingCampaigns();
      this.fetchRecruitmentCampaigns();
      this.fetchMallMemos();
      this.fetchStoreMemos();
    },
    async allEmailsClicked(): Promise<void> {
      this.loadingEmails = true;
      this.openPrompt('emails');
      if (this.modalData.row.commType === 'marketing-campaign' || this.modalData.row.commType === 'recruitment-campaign') {
        await this.fetchSystemEmails({ communicationId: this.campaignId });
      } else if (this.modalData.row.commType === 'mall-memo' || this.modalData.row.commType === 'store-memo') {
        await this.fetchSystemEmails({ communicationId: this.memoId });
      }
      this.loadingEmails = false;
    },
    async emailsClicked(event: any): Promise<void> {
      this.loadingEmails = true;
      this.openPrompt('emails');
      const mallId = event.mallId;
      if (this.modalData.row.commType === 'marketing-campaign' || this.modalData.row.commType === 'recruitment-campaign') {
        await this.fetchSystemEmails({ mallId: mallId, communicationId: this.campaignId });
      } else if (this.modalData.row.commType === 'mall-memo' || this.modalData.row.commType === 'store-memo') {
        await this.fetchSystemEmails({ mallId: mallId, communicationId: this.memoId });
      }
      this.loadingEmails = false;
    },
    updateTabCounts(review: any): void {
      if (review.data) {
        review = review.data;
      }
      if (this.modalData?.type === 'approval') {
        const approvalCount = (review.stores as Array<any>).reduce(
          (count, s) => (s.statuses.statusId === Status.Approved || s?.statuses?.socialStatusId === Status.Approved ? ++count : count),
          0,
        );
        const pendingCount = (review.stores as Array<any>).reduce(
          (count, s) =>
            s?.statuses?.statusId !== Status.Approved &&
            s?.statuses?.socialStatusId !== Status.Approved &&
            s?.statuses?.statusId !== Status.Declined &&
            s?.statuses?.socialStatusId !== Status.Declined
              ? ++count
              : count,
          0,
        );
        const declineCount = (review.stores as Array<any>).reduce(
          (count, s) => (s.statuses.statusId === Status.Declined || s?.statuses?.socialStatusId === Status.Declined ? ++count : count),
          0,
        );

        this.modalData.row.stats.approved = approvalCount;
        this.modalData.row.stats.pending = pendingCount;
        this.modalData.row.stats.declined = declineCount;
      }
    },
    async selectedActionClicked(event: string): Promise<void> {
      let selections: any = [];
      let mallIds: any = [];
      let index = 0;
      this.singleSelects.forEach((element) => {
        if (element) {
          if (event !== 'declined' || (event === 'declined' && this.tableData[index].statusDesc === 'Pending')) {
            selections.push(this.tableData[index].storeId);
          }
          mallIds.push(this.tableData[index].mallId);
        }
        index++;
      });
      if (this.modalData.row.commType === 'marketing-campaign') {
        let obj: any = {
          campaignId: this.campaignId,
          storeIds: selections,
          reasonForDeclineDescription: '',
        };
        if (event === 'accepted') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 8;
            obj.socialStatusId = 8;
          }
          this.approvedObject = obj;
          if (mallIds.length === 1) {
            this.mallLink = `${window.location.protocol}//${window.location.host}/review/mall-details?mallId=${mallIds[0]}`;
          }
          await this.fetchMallsChannelsHelper(selections);
          this.openPrompt('accept');
        }
        if (event === 'reset') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 16;
            obj.socialStatusId = 16;
          }
          await this.setMarketingCampaignReviewStoreStatus(obj);
          let review = await this.fetchMarketingCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
        if (event === 'declined') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 9;
            obj.socialStatusId = 9;
          }
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        let obj: any = {
          campaignId: this.campaignId,
          storeIds: selections,
          reasonForDeclineDescription: '',
        };
        if (event === 'accepted') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 8;
            obj.socialStatusId = 8;
          }
          this.approvedObject = obj;
          if (mallIds.length === 1) {
            this.mallLink = `${window.location.protocol}//${window.location.host}/review/mall-details?mallId=${mallIds[0]}`;
          }
          await this.fetchMallsChannelsHelper(selections);
          this.openPrompt('accept');
        }
        if (event === 'reset') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 16;
            obj.socialStatusId = 16;
          }
          await this.setRecruitmentCampaignReviewStoreStatus(obj);
          let review = await this.fetchRecruitmentCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
        if (event === 'declined') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 9;
            obj.socialStatusId = 9;
          }
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'mall-memo') {
        let obj: any = {
          memoId: this.memoId,
          storeIds: selections,
          reasonForDeclineDescription: '',
        };
        if (event === 'accepted') {
          obj.statusId = 8;
          if (this.communication?.questions && this.communication?.questions?.length > 0) {
            obj.answers = [];
            this.communication?.questions?.forEach((question: any) => {
              question.answer = '';
              question.answers = [];
              question.answers[0] = {
                memoQuestionId: question?.memoQuestionId,
                description: '',
                userId: this.user?.userId,
              };
            });
            this.questionObject = obj;
            this.openPrompt('question');
          } else {
            await this.setMallMemoReviewStoreStatus(obj);
            let review = await this.fetchMallMemoReview({
              memoId: this.memoId,
              type: 'user',
              userId: this.user?.userId,
            });
            this.updateTabCounts(review);
          }
        }
        if (event === 'reset') {
          obj.statusId = 16;
          await this.setMallMemoReviewStoreStatus(obj);
          let review = await this.fetchMallMemoReview({
            memoId: this.memoId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
        if (event === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'store-memo') {
        let obj: any = {
          memoId: this.memoId,
          storeIds: selections,
          reasonForDeclineDescription: '',
        };
        if (event === 'accepted') {
          obj.statusId = 8;
          if (this.communication?.questions && this.communication?.questions?.length > 0) {
            obj.answers = [];
            this.communication?.questions?.forEach((question: any) => {
              question.answer = '';
              question.answers = [];
              question.answers[0] = {
                memoQuestionId: question?.memoQuestionId,
                description: '',
                userId: this.user?.userId,
              };
            });
            this.questionObject = obj;
            this.openPrompt('question');
          } else {
            await this.setStoreMemoReviewStoreStatus(obj);
            let review = await this.fetchStoreMemoReview({
              memoId: this.memoId,
              type: 'user',
              userId: this.user?.userId,
            });
            this.updateTabCounts(review);
          }
        }
        if (event === 'reset') {
          obj.statusId = 16;
          await this.setStoreMemoReviewStoreStatus(obj);
          let review = await this.fetchStoreMemoReview({
            memoId: this.memoId,
            type: 'user',
            userId: this.user?.userId,
          });
          this.updateTabCounts(review);
        }
        if (event === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      this.fetchMarketingCampaigns();
      this.fetchRecruitmentCampaigns();
      this.fetchMallMemos();
      this.fetchStoreMemos();
    },
    async decline() {
      this.declining = true;
      this.declinedObject.reasonForDeclineId = 0;
      this.declinedObject.reasonForDeclineDescription = `${this.selectedReason}${this.reasonDescription ? ' - ' + this.reasonDescription : ''}`;
      if (this.modalData.row.commType === 'marketing-campaign') {
        await this.setMarketingCampaignReviewStoreStatus(this.declinedObject);
        let review = await this.fetchMarketingCampaignReview({
          campaignId: this.campaignId,
          type: 'user',
          userId: this.user?.userId,
        });
        this.updateTabCounts(review);
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        await this.setRecruitmentCampaignReviewStoreStatus(this.declinedObject);
        let review = await this.fetchRecruitmentCampaignReview({
          campaignId: this.campaignId,
          type: 'user',
          userId: this.user?.userId,
        });
        this.updateTabCounts(review);
      }
      if (this.modalData.row.commType === 'mall-memo') {
        await this.setMallMemoReviewStoreStatus(this.declinedObject);
        let review = await this.fetchMallMemoReview({
          memoId: this.memoId,
          type: 'user',
          userId: this.user?.userId,
        });
        this.updateTabCounts(review);
      }
      if (this.modalData.row.commType === 'store-memo') {
        await this.setStoreMemoReviewStoreStatus(this.declinedObject);
        let review = await this.fetchStoreMemoReview({
          memoId: this.memoId,
          type: 'user',
          userId: this.user?.userId,
        });
        this.updateTabCounts(review);
      }
      this.declining = false;
      this.closePrompt();
      this.fetchMarketingCampaigns();
      this.fetchRecruitmentCampaigns();
      this.fetchMallMemos();
      this.fetchStoreMemos();
    },
    async approve() {
      if (this.channels.length === 0) {
        this.$notify({
          title: 'CHANNELS REQUIRED',
          text: 'Please select at least one channel.',
          type: 'warn',
          duration: 5000,
        });
        return;
      }

      this.approving = true;
      this.approvedObject.channels = this.channels;
      this.approvedObject.adminApproval = true;
      if (this.modalData.row.commType === 'marketing-campaign') {
        await this.setMarketingCampaignReviewStoreStatus(this.approvedObject);
        let review = await this.fetchMarketingCampaignReview({
          campaignId: this.campaignId,
          type: 'user',
          userId: this.user?.userId,
        });
        this.updateTabCounts(review);
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        await this.setRecruitmentCampaignReviewStoreStatus(this.approvedObject);
        let review = await this.fetchRecruitmentCampaignReview({
          campaignId: this.campaignId,
          type: 'user',
          userId: this.user?.userId,
        });
        this.updateTabCounts(review);
      }
      this.approving = false;
      this.closePrompt();
      this.fetchMarketingCampaigns();
      this.fetchRecruitmentCampaigns();
      this.fetchMallMemos();
      this.fetchStoreMemos();
    },
    closePrompt(): void {
      this.promptOpen = false;
      this.selectedReason = '';
      this.reasonDescription = '';
      this.declinedObject = null;
      this.approvedObject = null;
      this.channels = [];
      this.mallLink = '';
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
      this.selectedReason = '';
      this.reasonDescription = '';

      if (type === 'follow-up') {
        this.getEmailTemplates('brand');
      }
    },
    async export(): Promise<void> {
      const expStatus: any = {
        sent: 'all',
        pending: '16',
        approved: '8',
        declined: '9',
      };
      this.buttonFeedback.exportSubmitted = true;
      this.buttonFeedback.exportLoading = false;
      let URL = '';
      if (this.modalData?.row?.commType === 'marketing-campaign') {
        URL = `${process.env.VUE_APP_URI}/exports/marketing-campaign-approvals?marketingCampaignId=${this.campaignId}&userId=${this.user?.userId}`;
      }
      if (this.modalData?.row?.commType === 'recruitment-campaign') {
        URL = `${process.env.VUE_APP_URI}/exports/recruitment-campaign-approvals?contactId=0&recruitmentCampaignId=${this.campaignId}&userId=${
          this.user?.userId
        }&social=0&exp=1&expStatus=${expStatus[this.activeTab]}`;
      }
      if (this.modalData?.row?.commType === 'mall-memo') {
        URL = `${process.env.VUE_APP_URI}/exports/mall-memo-approvals?contactId=0&memoId=${this.memoId}&userId=${
          this.user?.userId
        }&social=0&exp=1&expStatus=${expStatus[this.activeTab]}`;
      }
      if (this.modalData?.row?.commType === 'store-memo') {
        URL = `${process.env.VUE_APP_URI}/exports/store-memo-approvals?contactId=0&memoId=${this.memoId}&userId=${
          this.user?.userId
        }&social=0&exp=1&expStatus=${expStatus[this.activeTab]}`;
      }
      this.$notify({
        title: 'Export is in progress.',
        text: `You will receive an email with the required export file attached once it is ready. You can continue working in the app.`,
        type: 'info',
        duration: 12000,
      });
      setTimeout(() => {
        this.buttonFeedback.exportSubmitted = false;
      }, 12000);
      await axios.get(URL, {
        withCredentials: true,
      });
      // try {
      //   await axios.get(URL, {
      //     withCredentials: true,
      //   });

      //   this.buttonFeedback.exportSubmitted = true;
      //   this.$notify({
      //     title: 'Export is in progress.',
      //     text: `You will receive an email with a link to the export file once it is ready. You can continue working in the app.`,
      //     type: 'info',
      //     duration: 12000,
      //   });
      //   setTimeout(() => {
      //     this.buttonFeedback.exportSubmitted = false;
      //   }, 120000);
      // } catch (e) {
      //   this.buttonFeedback.exportSubmitted = true;
      //   this.buttonFeedback.exportLoading = false;
      //   this.$notify({
      //     text: "We couldn't process your export request, please try again.",
      //     type: 'error',
      //     duration: 3000,
      //   });
      //   setTimeout(() => {
      //     this.buttonFeedback.exportSubmitted = false;
      //   }, 3000);
      // }
    },
    declineReason() {
      switch (this.selectedReason) {
        case 'Incorrect Details':
          this.reasonMessage = 'Please help us understand what information is incorrect or missing.';
          break;
        case "Store Doesn't Exist":
          this.reasonMessage = '';
          break;
        case 'Other':
          this.reasonMessage = 'Please help us understand by providing more details.';
          break;
      }
    },
    redirectToBrandEmailTemplates() {
      const brandId = this.modalData.row.brandId;
      const tab = 'email-templates';
      const url = `/companies/brands/view?brandId=${brandId}&tab=${tab}`;
      window.open(url, '_blank');
    },
    async sendFollowUpEmail() {
      try {
        const payload = {
          commType: this.modalData.row.commType,
          communicationId: this.modalData.row.communicationId,
        };
        await this.saveFollowUpEmailReminder(payload);
        this.$notify({
          title: 'SUCCESS',
          text: 'Follow up email will be sent shortly!',
          type: 'success',
          duration: 5000,
        });
        this.closePrompt();
      } catch (err) {
        this.$notify({
          title: 'ERROR',
          text: 'Unable to send follow up email.',
          type: 'error',
          duration: 5000,
        });
      }
    },
    async fetchMallsChannelsHelper(storeIds: number[]) {
      if (this.modalData.row.commType === 'marketing-campaign') {
        await this.fetchMallsChannels({ storeIds: storeIds, marketingCampaignId: this.campaignId });
      } else if (this.modalData.row.commType === 'recruitment-campaign') {
        await this.fetchMallsChannels({ storeIds: storeIds, recruitmentCampaignId: this.campaignId });
      } else {
        await this.fetchMallsChannels({ storeIds: storeIds });
      }
    },
  },
  watch: {
    metaEmailTemplates: {
      deep: true,
      handler() {
        this.followUpEmailTemplate = this.metaEmailTemplates?.find(
          (template: any) => template?.reminderTypeId === ReminderType['Follow Up'],
        )?.emailTemplate;
        this.followUpEmailTemplate = this.followUpEmailTemplate?.replace(/class="non-editable"/g, '');
      },
    },
  },
});
