
import { defineComponent } from 'vue';
import Button from '@/atomic/atoms/Button/Button.vue';
import Tooltip from '@/atomic/atoms/Tooltip/Tooltip.vue';

export default defineComponent({
  components: {
    'arc-tooltip': Tooltip,
    'arc-button': Button,
  },

  props: {
    variables: {
      type: Array,
      required: true,
    },
  },

  data(): {
    tooltipText: string,
  } {
    return {
      tooltipText: 'click to copy',
    };
  },

  methods: {
    copy(value: string) {
      navigator.clipboard.writeText(value);
      this.tooltipText = 'copied';
      setTimeout(() => {
        this.tooltipText = 'click to copy';
      }, 1000);
    },
  },
});
