
import { defineComponent } from 'vue';
import Button from '../../atoms/Button/Button.vue';
import { dateFormatter } from '../../../utilities/Functions/formatting';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(tz);
dayjs.extend(utc);

export default defineComponent({
  components: {
    'arc-button': Button,
  },
  props: {
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    status: {
      type: String,
    },
    message: {
      type: String,
    },
    icon: {
      type: String,
    },
    type: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    auto: {
      type: String,
    },
    inputTitle: {
      type: String,
    },
    showToggle: {
      type: Boolean,
    },
    minDate: {
      type: Date,
    },
    maxDate: {
      type: String,
    },
    rangeErrorMessage: {
      type: String,
    },
    checkRange: {
      type: Boolean,
    },
    timezone: {
      type: String,
    },
  },

  data(): {
    selectedYear: number;
    selectedMonth: number;
    selectedDay: number;
    selectedDate: null | { year: number; month: number; day: number };
    showDatePicker: boolean;
    initialLoad: boolean;
  } {
    return {
      selectedYear: new Date().getUTCFullYear(),
      selectedMonth: new Date().getUTCMonth(),
      selectedDay: new Date().getUTCDate(),
      selectedDate: null,
      showDatePicker: false,
      initialLoad: true,
    };
  },
  mounted() {
    this.todaysDate;
  },
  computed: {
    selectedTimezone(): string {
      if (this.timezone) {
        return this.timezone;
      } else {
        return dayjs.tz.guess();
      }
    },
    minimumAllowedDate(): dayjs.Dayjs | null {
      if (this.minDate) {
        const minDateInSelectedTimezone: dayjs.Dayjs = dayjs(this.minDate)
          .tz(this.selectedTimezone)
          .startOf('day');
        return dayjs.utc(minDateInSelectedTimezone.format('YYYY-MM-DD')).startOf('day');
      } else {
        return null;
      }
    },
    maximumAllowedDate(): dayjs.Dayjs | null {
      if (this.maxDate) {
        const maxDateInSelectedTimezone: dayjs.Dayjs = dayjs(this.maxDate)
          .tz(this.selectedTimezone)
          .endOf('day');
        return dayjs.utc(maxDateInSelectedTimezone.format('YYYY-MM-DD')).startOf('day');
      } else {
        return null;
      }
    },
    todaysDate(): dayjs.Dayjs {
      return dayjs().tz(this.selectedTimezone).startOf('day');
    },
    selectedDateFormatted(): string {
      if (this.selectedDate) {
        let currentDate = new Date(Date.UTC(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day + 1));
        return dateFormatter(`${currentDate}`);
      } else if (this.modelValue) {
        // let dateString = dayjs(this.modelValue).tz(this.timezone).format('ddd, MMM DD YYYY HH:mm:ss');
        let dateString = this.modelValue;

        if (dateString?.includes('GMT')) {
          dateString = dateString.slice(0, 16);
        }

        if (dateString?.includes('Z')) {
          dateString = dateString.slice(0, 10);
        }

        let date = new Date(dateString);

        const modelYear = date.getUTCFullYear();
        const modelMonth = date.getUTCMonth();
        const modelDay = date.getUTCDate();
        const payload = { year: modelYear, month: modelMonth + 1, day: modelDay };

        this.setDate(payload);
      }

      return '';
    },
    inputStatus(): {
      'input--success': boolean;
      'input--warn': boolean;
      'input--error': boolean;
    } {
      return {
        'input--success': this.status === 'success',
        'input--warn': this.status === 'warn',
        'input--error': this.status === 'error',
      };
    },
    showIcon(): boolean {
      if (this.icon || ['success', 'error', 'warn'].includes(this.status as string)) {
        return true;
      }

      return false;
    },
    dateGrid(): { isToday: boolean; selected: boolean; isThisMonth: boolean; day: number; month: number; year: number }[] {
      const previousMonthDaysWithOffset = this.previousMonthDays
        .slice(-this.previousMonthsOffset)
        .map((el: { year: number; month: number; day: number }) => {
          const dateInTimezone = dayjs.tz(`${el.year}-${el.month}-${el.day}`, this.selectedTimezone);
          return {
            isToday: this.isTodayDayJS(dateInTimezone),
            isBeforeToday: this.isBeforeTodayDayJS(dateInTimezone),
            isThisMonth: false,
            selected: this.isSelectedDate({ year: el.year, month: el.month, day: el.day }),
            ...el,
          };
        });

      const currentMonthsMapped = this.currentMonthDays.map((el: { year: number; month: number; day: number }) => {
        const dateInTimezone = dayjs.tz(`${el.year}-${el.month}-${el.day}`, this.selectedTimezone);
        return {
          isToday: this.isTodayDayJS(dateInTimezone),
          isBeforeToday: this.isBeforeTodayDayJS(dateInTimezone),
          isThisMonth: true,
          selected: this.isSelectedDate({ year: el.year, month: el.month, day: el.day }),
          ...el,
        };
      });

      const nextMonthDaysWithOffset = this.nextMonthDays.slice(0, this.nextMonthsOffset).map((el: { year: number; month: number; day: number }) => {
        const dateInTimezone = dayjs.tz(`${el.year}-${el.month}-${el.day}`, this.selectedTimezone);
        return {
          isToday: this.isTodayDayJS(dateInTimezone),
          isBeforeToday: this.isBeforeTodayDayJS(dateInTimezone),
          isThisMonth: false,
          selected: this.isSelectedDate({ year: el.year, month: el.month, day: el.day }),
          ...el,
        };
      });

      return [...previousMonthDaysWithOffset, ...currentMonthsMapped, ...nextMonthDaysWithOffset];
    },
    month(): string {
      const months = {
        0: 'January',
        1: 'February',
        2: 'March',
        3: 'April',
        4: 'May',
        5: 'June',
        6: 'July',
        7: 'August',
        8: 'September',
        9: 'October',
        10: 'November',
        11: 'December',
      };

      return months[this.selectedMonth];
    },
    currentMonthDays(): { day: number; month: number; year: number }[] {
      const year = this.selectedYear;
      const month = this.selectedMonth + 1;

      const numberOfDays: number = new Date(Date.UTC(year, month, 0)).getUTCDate();
      const days: { day: number; month: number; year: number }[] = [];

      for (let day = 1; day <= numberOfDays; day++) {
        days.push({
          day,
          month,
          year,
        });
      }

      return days;
    },
    nextMonthDays(): { day: number; month: number; year: number }[] {
      const year = this.selectedMonth + 1 === 12 ? this.selectedYear + 1 : this.selectedYear;
      const month = this.selectedMonth + 1 === 12 ? 1 : this.selectedMonth + 2;

      const numberOfDays: number = new Date(Date.UTC(year, month, 0)).getUTCDate();
      const days: { day: number; month: number; year: number }[] = [];

      for (let day = 1; day <= numberOfDays; day++) {
        days.push({
          day,
          month,
          year,
        });
      }

      return days;
    },
    previousMonthDays(): { day: number; month: number; year: number }[] {
      const year: number = this.selectedMonth + 1 === 1 ? this.selectedYear - 1 : this.selectedYear;
      const month: number = this.selectedMonth + 1 === 1 ? 12 : this.selectedMonth;

      const numberOfDays: number = new Date(Date.UTC(year, month, 0)).getUTCDate();

      const days: { year: number; month: number; day: number }[] = [];

      for (let day = 1; day <= numberOfDays; day++) {
        days.push({
          day,
          month,
          year,
        });
      }

      return days;
    },
    previousMonthsOffset(): number {
      return (
        1 +
        new Date(
          this.selectedMonth + 1 === 1 ? this.selectedYear - 1 : this.selectedYear,
          this.selectedMonth + 1 === 1 ? 12 : this.selectedMonth,
          0,
        ).getDay()
      );
    },
    nextMonthsOffset(): number {
      return 6 - new Date(Date.UTC(this.selectedYear, this.selectedMonth, this.currentMonthDays.length)).getUTCDay();
    },
  },

  watch: {
    timezone: {
      handler() {
        this.selectedDate = null;
        this.todaysDate;
        this.minimumAllowedDate;
        this.maximumAllowedDate;
        this.$emit('update:modelValue', '');
      },
    },
  },

  methods: {
    datePickerHandler(showState: boolean): void {
      this.showDatePicker = showState;
    },
    isTodayDayJS(date: dayjs.Dayjs): boolean {
      return date.isSame(this.todaysDate, 'day');
    },
    isToday({ year, month, day }: { year: number; month: number; day: number }): boolean {
      const currentYear = new Date().getUTCFullYear();
      const currentMonth = new Date().getUTCMonth() + 1;
      const currentDay = new Date().getUTCDate();

      if (year === currentYear && month === currentMonth && day === currentDay) {
        return true;
      }

      return false;
    },
    isBeforeTodayDayJS(date: dayjs.Dayjs): boolean {
      return date.isBefore(this.todaysDate, 'day');
    },
    isBeforeToday({ year, month, day }: { year: number; month: number; day: number }): boolean {
      const today = new Date();
      let date = new Date(year, month - 1, day);
      today.setHours(0, 0, 0, 0);
      date.setHours(23, 59, 59, 999);

      if (date < today) {
        return true;
      }

      if (this.minDate && date < this.minDate) {
        return true;
      }

      return false;
    },
    isSelectedDate({ year, month, day }: { year: number; month: number; day: number }): boolean {
      if (this.selectedDate && year === this.selectedDate.year && month === this.selectedDate.month && day === this.selectedDate.day) {
        return true;
      }

      return false;
    },
    setDate(date: { year: number; month: number; day: number }, clicked?: boolean): void {
      if (
        !this.isBeforeTodayDayJS(dayjs.utc(dayjs(`${date.year}-${date.month}-${date.day}`).format('YYYY-MM-DD'))) &&
        (!this.initialLoad || clicked)
      ) {
        const minDate = this.minDate?.setHours(0, 0, 0, 0);
        let maxDate: any;
        if (this.maxDate) {
          const newDate = new Date(this.maxDate);
          maxDate = newDate?.setHours(23, 59, 59, 999);
        }
        if (
          this.checkRange &&
          ((minDate && new Date(date.year, date.month - 1, date.day).setHours(0, 0, 0, 0) < minDate) ||
            (maxDate && new Date(date.year, date.month - 1, date.day).setHours(23, 59, 59, 999) > maxDate))
        ) {
          return this.$notify({
            title: 'DATE OUT OF RANGE',
            text: this.rangeErrorMessage ?? 'Ensure the date selected is within allowed range.',
            type: 'error',
            duration: 5000,
          });
        } else {
          this.selectedMonth = date.month - 1;
          this.selectedDate = date;
          this.datePickerHandler(false);
          this.$emit('update:modelValue', this.selectedDateFormatted);
        }
      }

      if (this.initialLoad && !clicked) {
        this.selectedMonth = date.month - 1;
        this.selectedDate = date;
        this.datePickerHandler(false);
        this.$emit('update:modelValue', this.selectedDateFormatted);
        this.initialLoad = false;
      }
    },
    gotoPreviousMonth(): void {
      const year: number = this.selectedMonth + 1 === 1 ? this.selectedYear - 1 : this.selectedYear;
      const month: number = this.selectedMonth + 1 === 1 ? 11 : this.selectedMonth - 1;

      this.selectedYear = year;
      this.selectedMonth = month;
    },
    gotoNextMonth(): void {
      const year: number = this.selectedMonth + 1 === 12 ? this.selectedYear + 1 : this.selectedYear;
      const month: number = this.selectedMonth + 1 === 12 ? 0 : this.selectedMonth + 1;

      this.selectedYear = year;
      this.selectedMonth = month;
    },
    addSuffixToNumber(i: number): string {
      const j = i % 10;
      const k = i % 100;

      if (j === 1 && k !== 11) {
        return i + 'st';
      }

      if (j === 2 && k !== 12) {
        return i + 'nd';
      }

      if (j === 3 && k !== 13) {
        return i + 'rd';
      }

      return i + 'th';
    },
  },
});
