
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../atoms/Input/Input.vue';
import Dropdown from '../../../../atoms/Dropdown/Dropdown.vue';
import MultiSelectDropdown from '../../../../molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import Button from '../../../../atoms/Button/Button.vue';
import Tooltip from '../../../../atoms/Tooltip/Tooltip.vue';
import Modal from '../../../../atoms/Modal/Modal.vue';
import DatePicker from '../../../../molecules/DatePicker/DatePicker.vue';
import { FormBuilder, FormControl } from '../../../../../utilities/Forms';
import { Validators } from '../../../../../utilities/Validators/Validators';
import { IDropdownOption } from '../../../../../utilities/Interfaces/form.interfaces';
import SingleSelectInputSuggestion from '../../../../molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import MultiSelectInput from '../../../../molecules/Multi-SelectInput/MultiSelectInput.vue';
import Loader from '../../../../atoms/Loader/Loader.vue';
import Checkbox from '@/atomic/atoms/Checkbox/Checkbox.vue';
import Search from '@/atomic/atoms/Search/Search.vue';
import LocationSelector from '@/atomic/molecules/Locations-Selector/Location-Selector.vue';
import { IStore } from '../../../../../../../../interfaces/src/v2';

export default defineComponent({
  emits: {
    hasChangesMade: Boolean,
  },
  components: {
    'arc-input': Input,
    'arc-dropdown': Dropdown,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-multi-select-input': MultiSelectInput,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
    'arc-date-picker': DatePicker,
    'arc-loader': Loader,
    'arc-checkbox': Checkbox,
    'arc-search': Search,
    'arc-location-selector': LocationSelector,
  },

  computed: {
    ...mapGetters([
      'modalData',
      'user',
      'access',
      'permissions',
      'companyOptions',
      'brandOptions',
      'storeOptions',
      'loadingStores',
      'storeTagOptions',
    ]),
    updateDisabled(): boolean {
      return this.modalData?.row && !this.access?.superAdminAccess;
    },
    deleteDisabled(): boolean {
      return this.modalData?.row && !this.access?.superAdminAccess;
    },
    selectBrandPlaceholder(): string {
      return this.brandOptions && this.brandOptions.length > 0 ? 'Select' : 'Select Company First';
    },
    filteredLocations(): any {
      // set all location options
      let locations = this.storeOptions;

      if (this.formData?.controls?.campaignType?.value) {
        const campaignTypeFilter: any[] = [];
        locations?.forEach((location: any) => {
          location?.mall?.communicationTypes?.forEach((commType: any) => {
            if (commType?.communicationTypeId === this.formData?.controls?.campaignType?.value) {
              campaignTypeFilter.push(location);
            }
          });
        });
        locations = campaignTypeFilter;
      }

      let tagCount: any = this.tagValues?.length;

      // filter tags
      if (tagCount > 0) {
        const tagIds = this.tagValues?.map((tag: any) => tag.value);
        // filter locations by tags
        locations = locations.filter((location: any) => {
          const locationTags = location?.tags?.map((tag: any) => tag.tagId);
          if (this.tagComboType.value === 'or') {
            return tagIds?.some((lt: number) => locationTags?.includes(lt));
          } else if (this.tagComboType.value === 'and') {
            return tagIds?.every((lt: number) => locationTags?.includes(lt));
          }
        });
      }

      // filter search params
      if (this.searchFilter !== '') {
        let searchResults: any[] = [];
        locations.forEach((element: any) => {
          let matchFound = false;
          if (typeof Object.values(element) === 'string') {
            if (Object.values(element).includes(this.searchFilter)) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'number') {
            if (
              Object.values(element)
                .toString()
                .includes(this.searchFilter)
            ) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'object' && Object.values(element)) {
            Object.values(element)
              .filter((value) => value)
              .forEach((nestedElement: any) => {
                if (typeof nestedElement === 'string') {
                  if (nestedElement.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'number') {
                  if (nestedElement.toString().includes(this.searchFilter)) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'object' && nestedElement) {
                  Object.values(nestedElement)
                    .filter((value) => value)
                    .forEach((nestedElementL2: any) => {
                      if (typeof nestedElementL2 === 'string') {
                        if (nestedElementL2.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'number') {
                        if (nestedElementL2.toString().includes(this.searchFilter)) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'object' && nestedElementL2) {
                        Object.values(nestedElementL2)
                          .filter((value) => value)
                          .forEach((nestedElementL3: any) => {
                            if (typeof nestedElementL3 === 'string') {
                              if (nestedElementL3.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                                matchFound = true;
                              }
                            }
                            if (typeof nestedElementL3 === 'number') {
                              if (nestedElementL3.toString().includes(this.searchFilter)) {
                                matchFound = true;
                              }
                            }
                          });
                      }
                    });
                }
              });
          }
          if (matchFound) {
            searchResults.push(element);
          }
        });
        locations = searchResults;
      }
      locations = locations?.sort((a: IStore, b: IStore) =>
        a.storeNumber.toString().localeCompare(b.storeNumber.toString(), undefined, { numeric: true }),
      );
      return locations;
    },
    filteredLocationsSelected(): any {
      return this.filteredLocations.filter((location: any) => this.selectedLocationIds?.includes(location?.storeId));
    },
    filteredLocationsAvailable(): any {
      return this.filteredLocations.filter((location: any) => !this.selectedLocationIds?.includes(location?.storeId));
    },
    searchLocationsStyle(): string {
      return this.storeTagOptions?.length > 0 ? 'grid-column-end: span 15;' : 'grid-column-end: span 30;';
    },
  },

  watch: {
    companyOptions: {
      deep: true,
      handler() {
        this.companyOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.companyId === item?.value) {
            this.updateCompany(item);
          }
        });
      },
    },
    brandOptions: {
      deep: true,
      handler() {
        this.brandOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.brandId === item?.value) {
            this.brandValue = item;
          }
        });
      },
    },
    'formData.controls.locations.value': {
      deep: true,
      async handler(locations) {
        this.extractDataFromLocations(locations);
      },
    },
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
  },

  async mounted(): Promise<void> {
    if (this.modalData?.tagId) {
      this.modalData.row = await this.fetchTag({ tagId: this.modalData?.tagId });
    }

    this.loadCompanies();
    this.clearStores();
    this.setForm();
    this.loaded = true;
  },
  data(): {
    companyValue: IDropdownOption | null;
    brandValue: IDropdownOption | null;
    allowShowOptions: boolean;
    allowCompanyShowOptions: boolean;
    changesMade: boolean;
    formValid: boolean;
    promptOpen: boolean;
    promptType: string;
    submitting: boolean;
    updating: boolean;
    saving: boolean;
    deleting: boolean;
    formData: any;
    loaded: boolean;
    attemptedSubmit: boolean;
    tagValues: IDropdownOption[];
    searchFilter: string;
    selectedLocationIds: string[];
    tagComboTypes: IDropdownOption[] | null;
    tagComboType: IDropdownOption;
  } {
    return {
      companyValue: null,
      brandValue: null,
      allowShowOptions: false,
      allowCompanyShowOptions: false,
      changesMade: false,
      formValid: true,
      promptOpen: false,
      promptType: 'save',
      submitting: false,
      updating: false,
      saving: false,
      deleting: false,
      formData: null,
      loaded: false,
      attemptedSubmit: false,
      tagValues: [],
      searchFilter: '',
      selectedLocationIds: [],
      tagComboTypes: [
        {
          value: 'or',
          description: 'OR',
        },
        {
          value: 'and',
          description: 'AND',
        },
      ],
      tagComboType: {
        value: 'or',
        description: 'OR',
      },
    };
  },

  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'loadCompanies',
      'loadBrands',
      'fetchTag',
      'saveTag',
      'updateTag',
      'deleteTag',
      'clearStores',
      'loadStoreTags',
      'fetchStoresByBrandIds',
    ]),
    setForm(): void {
      // initializers
      let company = '';
      let brand = '';
      let description = '';
      let locations = [];

      if (this.modalData?.row) {
        company = this.modalData?.row?.companyId;
        brand = this.modalData?.row?.brandId;
        description = this.modalData?.row?.description;
        locations = this.modalData?.row?.stores;

        this.extractDataFromLocations(locations);
      }

      // set form based on user type
      this.formData = new FormBuilder({
        company: new FormControl(company, [Validators.required]),
        brand: new FormControl(brand, [Validators.required]),
        description: new FormControl(description, [Validators.required]),
        locations: new FormControl(locations, [Validators.required]),
      });

      setTimeout(() => {
        if (this.formData.controls.brand.value) {
          this.fetchStoresByBrandIds({ brandIds: [this.formData.controls.brand.value] });
        }
      }, 0);
    },
    updateCompany(value: IDropdownOption, fromForm = false) {
      this.companyValue = value;
      this.formData.controls.company.value = this.companyValue.value;
      this.loadBrands({ companyId: value.value });
      if (fromForm) {
        this.setChangesMade('UPDATE COMPANY');
        this.brandValue = null;
        this.formData.controls.brand.value = '';
        this.formData.controls.locations.value = [];
        this.selectedLocationIds = [];
        this.validateControl('company');
        setTimeout(() => {
          if (this.brandOptions && this.brandOptions?.length === 1) {
            this.updateBrand(this.brandOptions[0]);
          }
        }, 3000);
      }
    },
    clearCompany(value: any) {
      if (value === '') {
        this.companyValue = null;
        this.formData.controls.company.value = null;
        this.setChangesMade('clear company');
        this.validateControl('company');
      }
    },
    async updateBrand(value: IDropdownOption, fromForm = false) {
      this.setChangesMade('BRAND');
      this.validateControl('brand');
      this.formData.controls.locations.value = [];
      this.selectedLocationIds = [];
      if (value?.value) {
        this.fetchStoresByBrandIds({ brandIds: [value.value] });
        this.loadStoreTags({ brandIds: [value.value] });
      }
      this.brandValue = value;
      this.formData.controls.brand.value = this.brandValue?.value;
    },
    setChangesMade(type?: string) {
      this.changesMade = true;
    },
    validateControl(controlName: string): void {
      setTimeout(() => {
        this.formData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setChangesMade('validate control');
        }
      }, 0);
    },
    closePrompt(): void {
      this.promptOpen = false;
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
    },
    deletePrompt(): void {
      this.openPrompt('delete');
    },
    async delete(): Promise<void> {
      this.deleting = true;
      this.promptOpen = false;

      try {
        await this.deleteTag(this.modalData?.row?.tagId);
        this.deleting = false;
        this.promptOpen = false;
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
          this.$notify({
            title: 'SUCCESS',
            text: 'Tag deleted successfully!',
            type: 'success',
            duration: 5000,
          });
        }, 500);
      } catch (err) {
        this.deleting = false;
        this.promptOpen = false;
        this.$notify({
          title: 'ERROR',
          text: 'Unable to delete Tag.',
          type: 'error',
          duration: 5000,
        });
      }
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    async save(): Promise<void> {
      console.log(this.formData);
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors) {
        let payload = {
          companyId: this.formData?.controls?.company?.value,
          brandId: this.formData?.controls?.brand?.value,
          description: this.formData?.controls?.description?.value,
          storeIds: this.selectedLocationIds,
        };
        this.saving = true;

        try {
          await this.saveTag(payload);
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'Tag saved successfully!',
            type: 'success',
            duration: 5000,
          });
          this.changesMade = false;
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.saving = false;
          this.$notify({
            title: 'ERROR',
            text: 'Unable to create Tag',
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Tag form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async update(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors) {
        let payload = {
          companyId: this.formData?.controls?.company?.value,
          brandId: this.formData?.controls?.brand?.value,
          description: this.formData?.controls?.description?.value,
          storeIds: this.selectedLocationIds,
        };
        this.saving = true;

        try {
          await this.updateTag({
            payload,
            tagId: this.modalData?.row?.tagId,
          });
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'Tag updated successfully!',
            type: 'success',
            duration: 5000,
          });
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.saving = false;
          this.$notify({
            title: 'ERROR',
            text: 'Unable to update Tag',
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Tag form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    tagsUpdated(value: any): void {
      this.tagValues = value;
    },
    updateTagComboType(value: IDropdownOption) {
      this.tagComboType = value;
      this.setChangesMade('TAG COMBO TYPE');
      this.validateControl('campaignType');
    },
    updateSearchLocationsFilter(value: string) {
      this.searchFilter = value;
    },
    extractDataFromLocations(locations: IStore[]) {
      this.selectedLocationIds = [];
      if (locations?.length > 0) {
        locations.forEach((location: IStore) => {
          this.selectedLocationIds.push(location.storeId);
        });
      }
    },
    locationAdded(value: any): void {
      this.formData.controls.locations.value.push(value);
      this.formData.controls.locations.value.sort((a: IStore, b: IStore) =>
        a.storeNumber.toString().localeCompare(b.storeNumber.toString(), undefined, { numeric: true }),
      );
      this.setChangesMade('LOCATION ADDED');
    },
    locationRemoved(value: any): void {
      const index = this.formData.controls.locations.value.findIndex((item: any) => item.storeId === value.storeId);
      this.formData.controls.locations.value.splice(index, 1);
      this.setChangesMade('LOCATION REMOVED');
    },
    addAllLocations(): void {
      this.filteredLocationsAvailable.forEach((location: any) => {
        this.locationAdded(location);
      });
    },
    removeAllLocations(): void {
      this.filteredLocationsSelected.forEach((location: any) => {
        this.locationRemoved(location);
      });
    },
  },
});
